import { MoreVert } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';
import CustomPagination from '../../../../components/core/CustomPagination';
import CustomTableCell from '../../../../components/core/CustomTableCell';
import CustomTableLabel from '../../../../components/core/CustomTableLabel';
import SlideUpTransition from '../../../../components/core/SlideUpTransition';
import StyledTableRow from '../../../../components/core/StyledTableRow';
import { useStore } from '../../../../hooks/useStore';
import { observer } from 'mobx-react';
import { ListWorkflowsForOrganizationRequest } from 'protos/pb/orby_internal/orby_internal_service';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import React, { useEffect, useState } from 'react';
import { toastService } from '../../../../services/ToastService';
import {
  ACCENT_COLOR_1,
  PERMISSION_MANAGE_USERS,
} from '../../../../utils/constants';
import AssignHyperparameterToWorkflow from './AssignHyperparameterToWorkflow';
import WorkflowAnalysisForm from './WorkflowAnalysisForm';

const WorkflowsList: React.FC<{
  organizationId: string;
}> = observer(({ organizationId }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const store = useStore();
  const {
    loadingWorkflowsForOrganization,
    workflowsForOrganization,
    workflowsForOrganizationError,
    totalWorkflowsForOrganizationCount,
    getWorkflowsForOrganization,
    resetWorkflowsForOrganizationError,
    predictionAnalysisError,
    resetPredictionAnalysisError,
    hyperparameterNameDisplayNameMap,
  } = store.internalAppStore;
  const { user } = store.authStore;

  const [page, setPage] = useState(1);
  const [assignHyperparameterForWorkflow, setAssignHyperparameterForWorkflow] =
    useState<Workflow>();
  const rowsPerPage = 10;
  const pagedWorkflows = workflowsForOrganization?.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage,
  );
  const [selectedWorkflowForAnalysis, setSelectedWorkflowForAnalysis] =
    useState('');
  const [selectedWorkflowMenu, setSelectedWorkflowMenu] = useState<Workflow>(
    {},
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [displayNamePrefix, setDisplayNamePrefix] = useState('');

  useEffect(() => {
    refreshPage();
  }, []);

  useEffect(() => {
    refreshPage();
  }, [displayNamePrefix]);

  const refreshPage = () => {
    setPage(1);
    const req: ListWorkflowsForOrganizationRequest = {
      pageNumber: page,
      pageSize: rowsPerPage,
      organizationName: `organizations/${organizationId}`,
    };
    let filterStr = '';
    if (displayNamePrefix) {
      filterStr = `display_name_prefix=${displayNamePrefix}`;
    }
    req.filter = filterStr;
    getWorkflowsForOrganization(req, true);
  };

  useEffect(() => {
    if (workflowsForOrganizationError) {
      toastService.showError(
        `Failed to fetch workflows: ${workflowsForOrganizationError}`,
      );
      resetWorkflowsForOrganizationError();
    }
  }, [workflowsForOrganizationError, resetWorkflowsForOrganizationError]);

  useEffect(() => {
    if (predictionAnalysisError) {
      toastService.showError(
        `Failed to fetch prediction analysis: ${predictionAnalysisError}`,
      );
      resetPredictionAnalysisError();
    }
  }, [predictionAnalysisError]);

  const fetchNextWorkflows = async (nextPage: number) => {
    getWorkflowsForOrganization(
      {
        pageNumber: nextPage,
        pageSize: rowsPerPage,
        organizationName: `organizations/${organizationId}`,
      },
      false /* Refresh */,
    );
  };

  const getHyperparameter = (hyperparameterName?: string) => {
    if (
      !hyperparameterName ||
      hyperparameterName === 'hyperparameters/000000000000000000000000'
    ) {
      return '';
    }

    if (hyperparameterNameDisplayNameMap[hyperparameterName]) {
      return hyperparameterNameDisplayNameMap[hyperparameterName];
    }

    return hyperparameterName.split('/').pop();
  };

  return (
    <>
      {assignHyperparameterForWorkflow && (
        <Dialog
          fullScreen={fullScreen}
          open={!!assignHyperparameterForWorkflow}
          onClose={() => {
            setAssignHyperparameterForWorkflow(undefined);
          }}
          TransitionComponent={SlideUpTransition}
        >
          <AssignHyperparameterToWorkflow
            workflow={assignHyperparameterForWorkflow}
            onClose={() => {
              setAssignHyperparameterForWorkflow(undefined);
            }}
          />
        </Dialog>
      )}

      {selectedWorkflowForAnalysis !== '' && (
        <Dialog
          fullScreen={fullScreen}
          open={selectedWorkflowForAnalysis !== ''}
          onClose={() => {
            setSelectedWorkflowForAnalysis('');
          }}
          TransitionComponent={SlideUpTransition}
        >
          <WorkflowAnalysisForm
            workflowName={selectedWorkflowForAnalysis}
            onClose={() => {
              setSelectedWorkflowForAnalysis('');
            }}
          />
        </Dialog>
      )}

      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'flex-start'}
        width={'100%'}
        gap={'8px'}
        marginTop={'24px'}
        marginBottom={'24px'}
      >
        <Box>
          <TextField
            variant='outlined'
            value={displayNamePrefix}
            size={'small'}
            InputProps={{
              startAdornment: <GridSearchIcon fontSize='medium' />,
              sx: {
                '& .MuiOutlinedInput-input  ': {
                  WebkitTextFillColor: '#000000 !important',
                  height: '23px',
                  lineHeight: '16px',
                },
              },
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setDisplayNamePrefix(event.target.value)
            }
          />
        </Box>
      </Box>

      {loadingWorkflowsForOrganization ? (
        <Box display={'flex'} pt={'60px'} justifyContent={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            marginTop: '30px',
            borderRadius: '10px',
          }}
        >
          <TableContainer
            sx={{
              borderStartStartRadius: 'inherit',
              borderStartEndRadius: 'inherit',
            }}
          >
            <Table>
              <TableHead
                sx={{
                  backgroundColor: ACCENT_COLOR_1,
                }}
              >
                <TableRow>
                  <CustomTableCell title='Workflow Name'>
                    <CustomTableLabel label='Workflow Name' />
                  </CustomTableCell>
                  <CustomTableCell title='Description'>
                    <CustomTableLabel label='Description' />
                  </CustomTableCell>
                  <CustomTableCell title='Hyperparameter'>
                    <CustomTableLabel label='Hyperparameter' />
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pagedWorkflows.map((workflow, index) => (
                  <StyledTableRow
                    key={workflow.name}
                    tabIndex={0}
                    role='row'
                    sx={{
                      backgroundColor: index % 2 === 0 ? 'white' : '#f9f9f9',
                    }}
                  >
                    <CustomTableCell ellipsis={false}>
                      <span title={workflow.displayName ?? 'NO DATA'}>
                        {workflow.displayName ?? 'NO DATA'}
                      </span>
                    </CustomTableCell>
                    <CustomTableCell ellipsis={false}>
                      <span title={workflow.description ?? ''}>
                        {workflow.description ?? ''}
                      </span>
                    </CustomTableCell>
                    <CustomTableCell
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        title={getHyperparameter(
                          workflow.hyperparameterResourceName,
                        )}
                      >
                        {getHyperparameter(workflow.hyperparameterResourceName)}
                      </span>
                      <span>
                        <IconButton
                          aria-controls={open ? 'long-menu' : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-haspopup='true'
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.stopPropagation();
                            setAnchorEl(event.currentTarget);
                            setSelectedWorkflowMenu(workflow);
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      </span>
                    </CustomTableCell>
                  </StyledTableRow>
                ))}
                <Menu
                  autoFocus={false}
                  disableAutoFocusItem={true}
                  aria-hidden={false}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => {
                    setAnchorEl(null);
                  }}
                  slotProps={{
                    paper: {
                      style: {
                        boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.15)',
                        borderRadius: '4px',
                      },
                    },
                  }}
                >
                  {user?.permittedActions?.includes(
                    PERMISSION_MANAGE_USERS,
                  ) && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        setAssignHyperparameterForWorkflow(
                          selectedWorkflowMenu,
                        );
                      }}
                    >
                      Assign Hyperparameter
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedWorkflowForAnalysis(
                        selectedWorkflowMenu.name!,
                      );
                    }}
                  >
                    Download Prediction Analysis
                  </MenuItem>
                </Menu>
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            rowsPerPage={10}
            totalSize={totalWorkflowsForOrganizationCount}
            page={page - 1}
            onNextPage={(newPage: number) => {
              setPage(newPage + 1);
              if (
                workflowsForOrganization.length <
                  totalWorkflowsForOrganizationCount! &&
                workflowsForOrganization.length < (newPage + 1) * rowsPerPage
              ) {
                fetchNextWorkflows(newPage + 1);
              }
            }}
            onPrevPage={(newPage: number) => {
              setPage(newPage + 1);
            }}
          />
        </Box>
      )}
    </>
  );
});

export default WorkflowsList;
