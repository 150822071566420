import { Box } from '@mui/material';
import CustomModal from '../../components/core/CustomModal';
import CustomTypography from '../../components/core/CustomTypography';
import React from 'react';

interface FeatureFlagDeletionModalProps {
  open: boolean;
  handleClose: () => void;
  onSubmit: () => void;
}

const FeatureFlagDeletionModal: React.FC<FeatureFlagDeletionModalProps> = ({
  open,
  handleClose,
  onSubmit,
}) => {
  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      onPrimaryClick={() => onSubmit()}
      heading={'Delete Feature Flag?'}
      content={
        <Box sx={{ width: '433px', mb: '24px' }}>
          <CustomTypography>
            Are you sure you want to delete this feature flag? This action
            cannot be restored.
          </CustomTypography>
        </Box>
      }
      primaryLabel={'Delete'}
      secondaryLabel={'Cancel'}
    />
  );
};

export default React.memo(FeatureFlagDeletionModal);
