import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  ListItemText,
  TextField,
} from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../../../components/core/CustomTypography';
import { useStore } from '../../../../hooks/useStore';
import { observer } from 'mobx-react';
import { WorkflowTemplateType } from 'protos/common/workflow_common';
import {
  ListWorkflowsRequest,
  Workflow,
} from 'protos/pb/v1alpha2/workflows_service';
import React, { useEffect, useState } from 'react';
import { toastService } from '../../../../services/ToastService';

const WorkflowTemplatesSelector: React.FC<{
  selectedTemplates: WorkflowTemplateType[];
  setSelectedTemplates: React.Dispatch<
    React.SetStateAction<WorkflowTemplateType[]>
  >;
}> = observer(({ selectedTemplates, setSelectedTemplates }) => {
  const store = useStore();
  const {
    listWorkflowTemplates,
    loadingWorkflowTemplates,
    workflowTemplates,
    workflowTemplateTotalSize,
    listWorkflowTemplatesError,
    resetTemplatesError,
  } = store.workflowsStore;
  const selectedOrgInfo = store.userStore.selectedOrgInfo;
  const [page, setPage] = useState(1);
  const [inputValue, setInputValue] = useState('');
  const options = selectedTemplates
    .map((template) => {
      if (loadingWorkflowTemplates) {
        return undefined;
      }

      return workflowTemplates.find(
        (workflowTemplate) =>
          workflowTemplate.workflowTemplateType === template,
      );
    })
    .filter((option): option is Workflow => option !== undefined);
  const hasMoreItems =
    (workflowTemplateTotalSize ?? 0) > workflowTemplates.length;

  const fetchNextWorkflows = async (nextPage: number) => {
    const req = buildRequest();
    req.pageNumber = nextPage;
    req.pageSize = 15;
    listWorkflowTemplates(req, false /* Refresh */);
  };

  useEffect(() => {
    setPage(1);
    const req = buildRequest();
    req.pageNumber = 1;
    req.pageSize = 15;
    listWorkflowTemplates(req, true /* Refresh */);
  }, [selectedOrgInfo]);

  useEffect(() => {
    if (listWorkflowTemplatesError) {
      toastService.showError(
        `Failed to fetch workflow templates: ${listWorkflowTemplatesError}`,
      );
      resetTemplatesError();
    }
  }, [listWorkflowTemplatesError]);

  const buildRequest = () => {
    const req: ListWorkflowsRequest = {};
    req.filter = 'is_template=true';
    if (selectedOrgInfo) {
      req.orgResourceName = selectedOrgInfo.orgResourceName;
    }
    return req;
  };

  const handleLoadMore = () => {
    if (loadingWorkflowTemplates || !hasMoreItems) {
      return;
    }
    const nextPage = page + 1;
    setPage(nextPage);
    fetchNextWorkflows(nextPage);
  };

  const handleChange = (event: any, newValue: Workflow[]) => {
    setSelectedTemplates(
      newValue.map((template) => template.workflowTemplateType!),
    );
  };

  const handleInputChange = (event: any, newValue: string) => {
    setInputValue(newValue);
  };

  return (
    <>
      <CustomTypography
        typographyType={TypographyType.Label}
        sx={{ margin: '5px 0' }}
      >
        Select Workflow Templates for Org
      </CustomTypography>
      <Autocomplete
        multiple
        disableCloseOnSelect
        value={options}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        options={workflowTemplates}
        getOptionLabel={(option) => option.displayName ?? ''}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            placeholder='Type to search workflow templates'
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox checked={selected} style={{ marginRight: 8 }} />
            <ListItemText primary={option.displayName} />
          </li>
        )}
        ListboxProps={{
          style: {
            maxHeight: 250,
            overflow: 'auto',
          },
          onScroll: (event: any) => {
            const bottomReached =
              event.target.scrollHeight - event.target.scrollTop ===
              event.target.clientHeight;
            if (bottomReached && !loadingWorkflowTemplates) {
              handleLoadMore();
            }
          },
        }}
        loading={loadingWorkflowTemplates}
        loadingText={<CircularProgress size={24} />}
        sx={{ margin: '16px 0' }}
      />
    </>
  );
});

export default WorkflowTemplatesSelector;
