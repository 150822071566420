import { Close } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import CustomTypography from '../../../components/core/CustomTypography';
import { useStore } from '../../../hooks/useStore';
import { observer } from 'mobx-react';
import { UserWithPrivilageLevel } from 'protos/pb/orby_internal/orby_internal_service';
import React, { useEffect } from 'react';
import {
  PERMISSION_ACCESS_INTERNAL_APP,
  PERMISSION_HAS_BILLING_ACCESS,
  PERMISSION_MANAGE_USERS,
} from '../../../utils/constants';

const UserPermissionsDisplay: React.FC<{
  user: UserWithPrivilageLevel;
  onClose: () => void;
}> = observer(({ user, onClose }) => {
  const store = useStore();
  const { getUserPermissions, userPermissions } = store.internalAppStore;

  useEffect(() => {
    getUserPermissions({ userResourceName: `users/${user.userId}` });
  }, []);

  if (!userPermissions) {
    return (
      <Box display={'flex'} pt={'60px'} justifyContent={'center'}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container spacing={2} sx={{ padding: '16px' }}>
      <DialogTitle>Permissions for {user.email}</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'black',
        }}
      >
        <Close />
      </IconButton>
      <Grid item xs={12}>
        <CustomTypography sx={{ fontWeight: 'bold' }}>
          Can Manage Users
        </CustomTypography>
        <CustomTypography>
          {userPermissions?.includes(PERMISSION_MANAGE_USERS) ? 'YES' : 'NO'}
        </CustomTypography>
      </Grid>
      <Grid item xs={12}>
        <CustomTypography sx={{ fontWeight: 'bold' }}>
          Has Billing Access
        </CustomTypography>
        <CustomTypography>
          {userPermissions?.includes(PERMISSION_HAS_BILLING_ACCESS)
            ? 'YES'
            : 'NO'}
        </CustomTypography>
      </Grid>
      <Grid item xs={12}>
        <CustomTypography sx={{ fontWeight: 'bold' }}>
          Has Access to Internal App
        </CustomTypography>
        <CustomTypography>
          {userPermissions?.includes(PERMISSION_ACCESS_INTERNAL_APP)
            ? 'YES'
            : 'NO'}
        </CustomTypography>
      </Grid>
      <Grid item xs={12}>
        <CustomTypography sx={{ fontWeight: 'bold' }}>
          Workflows Access
        </CustomTypography>
        <CustomTypography>
          {userPermissions
            ?.filter((p) => p.endsWith('_internal_reviewer'))
            .map((p) => p.replace('_internal_reviewer', ''))
            .join(', ')}
        </CustomTypography>
      </Grid>
    </Grid>
  );
});

export default UserPermissionsDisplay;
