import { makeAutoObservable, observable, runInAction } from 'mobx';
import {
  CreateFeatureFlagRequest,
  DeleteFeatureFlagRequest,
  FeatureFlag,
  GetFeatureFlagRequest,
  GetFeatureFlagsForOrgAndUserRequest,
  ListFeatureFlagsRequest,
  UpdateFeatureFlagRequest,
} from 'protos/pb/v1alpha2/feature_flag_service';
import { featureFlagService } from '../services/FeatureFlagService';
import { RootStore } from './store';

class FeatureFlagsStore {
  rootStore: RootStore;
  @observable featureFlags: FeatureFlag[] = [];
  @observable totalSize?: number = 0;
  @observable loadingFeatureFlagsList = false;
  @observable listFeatureFlagsError?: Error;

  @observable createdFeatureFlag?: FeatureFlag;
  @observable updatedFeatureFlag?: FeatureFlag;

  @observable processingFeatureFlag = false;
  @observable processFeatureFlagError?: Error;

  @observable selectedFeatureFlag?: FeatureFlag;
  @observable selectedFeatureFlagLoading = false;
  @observable selectedFeatureFlagError?: Error;

  @observable featureFlagsForOrgAndUser?: { [name: string]: boolean } = {};
  @observable featureFlagsForOrgAndUserLoading = true;
  @observable featureFlagsForOrgAndUserError?: Error;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  listFeatureFlags = async (req: ListFeatureFlagsRequest, refresh = false) => {
    runInAction(() => {
      this.loadingFeatureFlagsList = true;
      this.listFeatureFlagsError = undefined;
    });
    try {
      const { response, error } = await featureFlagService.getFeatureFlags(req);
      runInAction(() => {
        if (response) {
          if (refresh) {
            this.featureFlags = response.featureFlags ?? [];
          } else {
            this.featureFlags.push(...(response.featureFlags ?? []));
          }
          this.totalSize = response.totalSize;
        } else {
          this.listFeatureFlagsError = error;
        }
        this.loadingFeatureFlagsList = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingFeatureFlagsList = false;
        this.listFeatureFlagsError = error as Error;
      });
    }
  };

  createFeatureFlag = async (req: CreateFeatureFlagRequest) => {
    this.processingFeatureFlag = true;
    try {
      const { response, error } =
        await featureFlagService.createFeatureFlag(req);
      runInAction(() => {
        if (response) {
          this.createdFeatureFlag = response;
        } else {
          this.processFeatureFlagError = error;
        }
        this.processingFeatureFlag = false;
      });
    } catch (error) {
      runInAction(() => {
        this.processingFeatureFlag = false;
        this.processFeatureFlagError = error as Error;
      });
    }
  };

  updateFeatureFlag = async (req: UpdateFeatureFlagRequest) => {
    this.processingFeatureFlag = true;
    try {
      const { response, error } =
        await featureFlagService.updateFeatureFlag(req);
      runInAction(() => {
        if (response) {
          this.updatedFeatureFlag = response;
        } else {
          this.processFeatureFlagError = error;
        }
        this.processingFeatureFlag = false;
      });
    } catch (error) {
      runInAction(() => {
        this.processingFeatureFlag = false;
        this.processFeatureFlagError = error as Error;
      });
    }
  };

  deleteFeatureFlag = async (req: DeleteFeatureFlagRequest) => {
    this.processingFeatureFlag = true;
    try {
      await featureFlagService.deleteFeatureFlag(req);
      runInAction(() => {
        this.featureFlags = this.featureFlags.filter((ff) => ff.id !== req.id);
        this.processingFeatureFlag = false;
      });
    } catch (error) {
      runInAction(() => {
        this.processingFeatureFlag = false;
        this.processFeatureFlagError = error as Error;
      });
    }
  };

  getFeatureFlag = async (req: GetFeatureFlagRequest) => {
    this.selectedFeatureFlagLoading = true;
    try {
      const { response, error } = await featureFlagService.getFeatureFlag(req);
      runInAction(() => {
        if (response) {
          this.selectedFeatureFlag = response;
        } else {
          this.selectedFeatureFlagError = error;
        }
        this.selectedFeatureFlagLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.selectedFeatureFlagLoading = false;
        this.selectedFeatureFlagError = error as Error;
      });
    }
  };

  getFeatureFlagsForOrgAndUser = async (
    req: GetFeatureFlagsForOrgAndUserRequest,
  ) => {
    this.featureFlagsForOrgAndUserLoading = true;
    try {
      const { response, error } =
        await featureFlagService.getFeatureFlagsForOrgAndUser(req);
      runInAction(() => {
        if (response) {
          this.featureFlagsForOrgAndUser = response.featureFlags;
        } else {
          this.featureFlagsForOrgAndUserError = error;
        }
        this.featureFlagsForOrgAndUserLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.featureFlagsForOrgAndUserLoading = false;
        this.featureFlagsForOrgAndUserError = error as Error;
      });
    }
  };

  setSelectedFeatureFlag = (featureFlag?: FeatureFlag) => {
    runInAction(() => {
      this.selectedFeatureFlag = featureFlag;
    });
  };

  clearErrors = () => {
    runInAction(() => {
      this.selectedFeatureFlagError = undefined;
      this.processFeatureFlagError = undefined;
    });
  };
}

export default FeatureFlagsStore;
