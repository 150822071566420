import {
  AutoFixHigh,
  CorporateFare,
  Group,
  KeyOutlined,
  Announcement,
} from '@mui/icons-material';
import React, { ReactElement } from 'react';
import {
  ROUTE_NAME_FEATURE_FLAGS,
  ROUTE_NAME_HYPERPARAMETERS,
  ROUTE_NAME_ORGANIZATIONS,
  ROUTE_NAME_USERS,
  ROUTE_NAME_ANNOUNCEMENTS,
} from '../../utils/constants';

export interface DrawerTabType {
  title: string;
  route: string;
  icon: ReactElement;
  description?: string;
}

export const DRAWER_TABS: DrawerTabType[] = [
  {
    title: 'Organizations',
    route: ROUTE_NAME_ORGANIZATIONS,
    icon: <CorporateFare />,
  },
  {
    title: 'Hyperparameters',
    route: ROUTE_NAME_HYPERPARAMETERS,
    icon: <AutoFixHigh />,
  },
  {
    title: 'Users',
    route: ROUTE_NAME_USERS,
    icon: <Group />,
  },
  {
    title: 'Feature Flags',
    route: ROUTE_NAME_FEATURE_FLAGS,
    icon: <KeyOutlined />,
  },
  {
    title: 'Announcements',
    route: ROUTE_NAME_ANNOUNCEMENTS,
    icon: <Announcement />,
  },
];
