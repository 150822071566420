import { Close } from '@mui/icons-material';
import {
  Button,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../../components/core/CustomTypography';
import { useStore } from '../../../hooks/useStore';
import { observer } from 'mobx-react';
import {
  Role,
  roleFromJSON,
  UserWithPrivilageLevel,
} from 'protos/pb/orby_internal/orby_internal_service';
import { Organization } from 'protos/pb/v1alpha1/organization';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import React from 'react';
import { toastService } from '../../../services/ToastService';
import {
  ROLE_ADMIN,
  ROLE_BILLING_MANAGER,
  ROLE_REVIEWER,
  ROLE_USER,
} from '../../../utils/constants';
import OrganizationSelector from './OrganizationSelector';
import WorkflowSelector from './WorkflowSelector';

const UserRoleAssignment: React.FC<{
  user: UserWithPrivilageLevel;
  onClose: () => void;
}> = observer(({ user, onClose }) => {
  const store = useStore();
  const { updateUser } = store.internalAppStore;
  const [role, setRole] = React.useState<string>('');
  const [organization, setOrganization] = React.useState<Organization>({});
  const [workflow, setWorkflow] = React.useState<Workflow>({});
  let isButtonEnabled =
    roleFromJSON(role) !== Role.UNRECOGNIZED &&
    roleFromJSON(role) !== Role.UNSPECIFIED;

  if (role === ROLE_REVIEWER && (!organization.name || !workflow.name)) {
    isButtonEnabled = false;
  }

  const showWorkflowsButton = role === ROLE_REVIEWER && organization.name;

  const submitForm = async () => {
    if (role.length === 0) {
      return;
    }

    const apiRole: Role = roleFromJSON(role);
    if (apiRole === Role.UNSPECIFIED || apiRole === Role.UNRECOGNIZED) {
      return;
    }

    if (
      apiRole === Role.INTERNAL_REVIEWER &&
      (workflow.name ?? '').length === 0
    ) {
      return;
    }

    const error = await updateUser({
      email: user.email,
      role: apiRole,
      organizationResourceName: workflow.name ? undefined : organization.name,
      workflowResourceName: workflow.name,
    });

    if (error) {
      toastService.showError(`Failed to update user with error: ${error}`);
      onClose();
    } else {
      setRole('');
      setOrganization({});
      setWorkflow({});
      toastService.showSuccess('User updated successfully');
      onClose();
    }
  };

  return (
    <Grid container spacing={2} sx={{ padding: '16px' }}>
      <DialogTitle>Role Assignment for {user.email}</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'red',
        }}
      >
        <Close />
      </IconButton>
      <Grid item xs={12}>
        <CustomTypography
          typographyType={TypographyType.Label}
          sx={{ margin: '5px 0' }}
        >
          Select Role
        </CustomTypography>
        <Select
          value={role}
          onChange={(event: SelectChangeEvent) => {
            setRole(event.target.value);
          }}
          inputProps={{ 'aria-label': 'Without label' }}
          displayEmpty
          fullWidth
        >
          <MenuItem value={ROLE_USER}>User</MenuItem>
          <MenuItem value={ROLE_ADMIN}>Internal Admin</MenuItem>
          <MenuItem value={ROLE_BILLING_MANAGER}>Billing Manager</MenuItem>
          <MenuItem value={ROLE_REVIEWER}>Internal Reviewer</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        {role === ROLE_REVIEWER && (
          <OrganizationSelector
            selectedValue={organization}
            setSelectedValue={setOrganization}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {showWorkflowsButton && (
          <WorkflowSelector
            selectedValue={workflow}
            setSelectedValue={setWorkflow}
            selectedOrganization={organization.name!}
            isDisabled={!showWorkflowsButton}
          />
        )}
      </Grid>
      <DialogActions
        sx={{
          justifyContent: 'end',
          width: '100%',
        }}
      >
        <Button
          variant='contained'
          color='primary'
          onClick={submitForm}
          disabled={!isButtonEnabled}
          sx={{ marginTop: '10px' }}
        >
          Submit
        </Button>
      </DialogActions>
    </Grid>
  );
});

export default UserRoleAssignment;
