import { useStore } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import LoginPage from './LoginPage';

const AuthPage: React.FC = observer(() => {
  const navigate = useNavigate();
  const store = useStore();
  const { selectedOrgInfo, loggedInUser } = store.userStore;

  useEffect(() => {
    if (loggedInUser && !selectedOrgInfo) {
      navigate('/login');
    }
  }, [loggedInUser, selectedOrgInfo]);

  return (
    <Suspense fallback={<span>Loading</span>}>
      <Routes>
        <Route path='/login/*' element={<LoginPage />} />
      </Routes>
    </Suspense>
  );
});

export default AuthPage;
