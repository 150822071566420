import { Empty } from 'protos/google/protobuf/empty';
import {
  GetNewGoogleTokenRequest,
  GetNewGoogleTokenResponse,
  RefreshRequest,
  RefreshResponse,
  TokensClientImpl,
} from 'protos/pb/v1alpha1/tokens_service';
import { User } from 'protos/pb/v1alpha1/user';
import {
  LoginRequest,
  LoginResponse,
  LogoutRequest,
  RegisterRequest,
  SingleSignOnRequest,
  UsersClientImpl,
} from 'protos/pb/v1alpha1/users_service';
import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtils';
import { Token, storageService } from './StorageService';

export const TIME = {
  MILLISECOND: 1,
  get SECOND() {
    return 1000 * this.MILLISECOND;
  },
};

export class AuthService {
  private static instance: AuthService;
  private static client = new UsersClientImpl(rpcWithErrorHandling);
  private static tokenClient = new TokensClientImpl(rpcWithErrorHandling);

  public static getInstance(): AuthService {
    if (!this.instance) {
      this.instance = new AuthService();
    }
    return this.instance;
  }

  async login(req: LoginRequest): Promise<LoginResponse> {
    return new Promise<LoginResponse>((resolve, reject) => {
      AuthService.client
        .Login(req, getMetaData({}))
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => reject(err));
    });
  }

  async googleLogin(req: SingleSignOnRequest): Promise<LoginResponse> {
    return new Promise<LoginResponse>((resolve, reject) => {
      AuthService.client
        .GoogleSingleSignOnForInternalApp(req, getMetaData({}))
        .then((resp) => resolve(resp))
        .catch((err) => reject(err));
    });
  }

  async registerUser(req: RegisterRequest): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      AuthService.client
        .Register(req, getMetaData({}))
        .then((resp) => resolve(resp.user as User))
        .catch((err) => reject(err));
    });
  }

  async registerGoogleToken(
    req: GetNewGoogleTokenRequest,
  ): Promise<GetNewGoogleTokenResponse> {
    const authorization = await storageService.getAuthorizationHeader();
    return new Promise<GetNewGoogleTokenResponse>((resolve, reject) => {
      AuthService.tokenClient
        .GetNewGoogleToken(req, getMetaData({ authorization }))
        .then((resp: any) => resolve(resp))
        .catch((err: any) => reject(err));
    });
  }

  async refreshTokenIfNeeded(token: Token): Promise<Token> {
    return new Promise<Token>((resolve, reject) => {
      if (
        token.accessTokenExpiresAt &&
        token.accessTokenExpiresAt > Date.now() + 5 * TIME.SECOND
      ) {
        resolve(token);
        return;
      }
      const refreshRequest: RefreshRequest = {};
      refreshRequest.refreshToken = token.refreshToken as string;
      AuthService.tokenClient
        .Refresh(refreshRequest, getMetaData({}))
        .then((resp: RefreshResponse) => {
          const updatedToken: Token = {
            sessionId: token.sessionId,
            refreshToken: token.refreshToken,
            refreshTokenExpiresAt: token.refreshTokenExpiresAt,
            accessToken: resp.accessToken,
            accessTokenExpiresAt: resp.accessTokenExpiresAt?.getTime(),
            platform: token.platform,
          };
          storageService.setStoredToken(updatedToken).then(() => {
            resolve(updatedToken);
          });
        })
        .catch((err: any) => reject(err));
    });
  }

  async logoutUser(req: LogoutRequest): Promise<Empty> {
    const authorization = await storageService.getAuthorizationHeader();
    return AuthService.client.Logout(req, { authorization } as any);
  }
}

export const authService = AuthService.getInstance();
