import { Add, CorporateFare } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Dialog,
  Fab,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import SlideUpTransition from '../../components/core/SlideUpTransition';
import { CurrentBreadcrumb } from '../../components/core/StyledBreadcrumb';
import React, { memo, useState } from 'react';
import CreateOrganization from './CreateOrganization/CreateOrganization';
import OrganizationsList from './components/OrganizationsList';
import { observer } from 'mobx-react';
import { useStore } from '../../hooks/useStore';
import { PERMISSION_ACCESS_INTERNAL_APP } from '../../utils/constants';
import { Organization } from 'protos/pb/v1alpha1/organization';
import EditOrganization from './EditOrganization/EditOrganization';

const Organizations: React.FC = observer(() => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const store = useStore();
  const { user } = store.authStore;

  const [addOrganizationDialogOpen, setAddOrganizationDialogOpen] =
    useState(false);
  const [updateOrganizationOpenFor, setUpdateOrganizationOpenFor] = useState<
    Organization | undefined
  >(undefined);

  const handleAddOrganizationDialogOpen = () => {
    setAddOrganizationDialogOpen(true);
  };

  const handleAddOrganizationDialogClose = () => {
    setAddOrganizationDialogOpen(false);
  };

  return (
    <Box
      sx={{
        margin: '16px',
      }}
    >
      <Dialog
        fullScreen={fullScreen}
        open={addOrganizationDialogOpen}
        onClose={handleAddOrganizationDialogClose}
        TransitionComponent={SlideUpTransition}
      >
        <CreateOrganization onClose={handleAddOrganizationDialogClose} />
      </Dialog>
      {updateOrganizationOpenFor && (
        <Dialog
          fullScreen={fullScreen}
          open={!!updateOrganizationOpenFor}
          onClose={() => setUpdateOrganizationOpenFor(undefined)}
          TransitionComponent={SlideUpTransition}
        >
          <EditOrganization
            onClose={() => setUpdateOrganizationOpenFor(undefined)}
            selectedOrganization={updateOrganizationOpenFor}
          />
        </Dialog>
      )}
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <Breadcrumbs separator='>'>
          <CurrentBreadcrumb
            label='Organizations'
            icon={<CorporateFare fontSize='small' color='info' />}
          />
        </Breadcrumbs>
        {user?.permittedActions?.includes(PERMISSION_ACCESS_INTERNAL_APP) && (
          <Fab
            variant='extended'
            color='primary'
            onClick={handleAddOrganizationDialogOpen}
          >
            <Add sx={{ marginRight: '4px' }} />
            Organization
          </Fab>
        )}
      </Stack>
      <Box>
        <OrganizationsList
          setUpdateOrganizationOpenFor={setUpdateOrganizationOpenFor}
        />
      </Box>
    </Box>
  );
});

export default memo(Organizations);
