import { TablePagination } from '@mui/material';
import React from 'react';

interface Props {
  rowsPerPage: number;
  totalSize?: number;
  /**
   * The current page number. The page number is zero-based.
   */
  page: number;
  justifyContent?: 'center' | 'end';
  onNextPage: (newPage: number) => void;
  onPrevPage: (newPage: number) => void;
}

const CustomPagination: React.FC<Props> = ({
  rowsPerPage,
  totalSize = 0,
  page,
  onNextPage,
  onPrevPage,
}) => {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    if (newPage > page) {
      onNextPage(newPage);
    } else {
      onPrevPage(newPage);
    }
  };

  return (
    <TablePagination
      component='div'
      count={totalSize}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[]}
    />
  );
};

export default React.memo(CustomPagination);
