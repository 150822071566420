import { Box, Grid, TextField, Tooltip } from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../../../components/core/CustomTypography';
import { DocumentClassificationTunableConfig } from 'protos/common/hyperparameter';
import React from 'react';

const DocumentClassificationStep: React.FC<{
  isViewOnly: boolean;
  value: DocumentClassificationTunableConfig;
  setValue: (
    newDocumentClassificationTunableConfig: DocumentClassificationTunableConfig,
  ) => void;
}> = ({ isViewOnly, value, setValue }) => {
  return (
    <Box width={'70%'} marginBottom={'15px'} marginLeft={'15px'}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CustomTypography
            typographyType={TypographyType.Label}
            sx={{ margin: '5px 0' }}
          >
            Model Name
          </CustomTypography>
          <Tooltip title={isViewOnly ? 'Insufficient Permissions' : ''}>
            <TextField
              variant='outlined'
              value={value?.modelName ?? ''}
              disabled={isViewOnly}
              size={'small'}
              InputProps={{
                sx: {
                  bgcolor: isViewOnly ? '#DDDBDA' : '',
                  '& .MuiOutlinedInput-input  ': {
                    WebkitTextFillColor: '#000000 !important',
                    cursor: isViewOnly ? 'not-allowed' : 'pointer',
                    height: '23px',
                    lineHeight: '16px',
                  },
                },
              }}
              onChange={(e) =>
                setValue({
                  ...value,
                  modelName: e.target.value,
                })
              }
              fullWidth
            />
          </Tooltip>
        </Grid>
        <Grid item xs={6}>
          <CustomTypography
            typographyType={TypographyType.Label}
            sx={{ margin: '5px 0' }}
          >
            Model Family
          </CustomTypography>
          <Tooltip title={isViewOnly ? 'Insufficient Permissions' : ''}>
            <TextField
              variant='outlined'
              value={value?.modelFamily ?? ''}
              disabled={isViewOnly}
              size={'small'}
              InputProps={{
                sx: {
                  bgcolor: isViewOnly ? '#DDDBDA' : '',
                  '& .MuiOutlinedInput-input  ': {
                    WebkitTextFillColor: '#000000 !important',
                    cursor: isViewOnly ? 'not-allowed' : 'pointer',
                    height: '23px',
                    lineHeight: '16px',
                  },
                },
              }}
              onChange={(e) =>
                setValue({
                  ...value,
                  modelFamily: e.target.value,
                })
              }
              fullWidth
            />
          </Tooltip>
        </Grid>
        <Grid item xs={6}>
          <CustomTypography
            typographyType={TypographyType.Label}
            sx={{ margin: '5px 0' }}
          >
            Pipeline Type
          </CustomTypography>
          <Tooltip title={isViewOnly ? 'Insufficient Permissions' : ''}>
            <TextField
              variant='outlined'
              value={value?.pipelineType ?? ''}
              disabled={isViewOnly}
              size={'small'}
              InputProps={{
                sx: {
                  bgcolor: isViewOnly ? '#DDDBDA' : '',
                  '& .MuiOutlinedInput-input  ': {
                    WebkitTextFillColor: '#000000 !important',
                    cursor: isViewOnly ? 'not-allowed' : 'pointer',
                    height: '23px',
                    lineHeight: '16px',
                  },
                },
              }}
              onChange={(e) =>
                setValue({
                  ...value,
                  pipelineType: e.target.value,
                })
              }
              fullWidth
            />
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DocumentClassificationStep;
