export const ELLIPSIS_STYLE = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const GOOGLE_DEFAULT_SCOPE = 'email profile';
export const GOOGLE_DRIVE_SCOPE = 'https://www.googleapis.com/auth/drive';
export const GOOGLE_GMAIL_SCOPE =
  'https://www.googleapis.com/auth/gmail.modify';
export const ORBYAI_UNKNOWN = 'OrbyAI_Unknown';
export const IS_PROD = process.env.REACT_APP_ENV === 'production';
export const IS_DEV = process.env.REACT_APP_ENV === 'development';
export const IS_LOCAL = process.env.REACT_APP_ENV === 'local';

export const ROUTE_NAME_ORGANIZATIONS = 'organizations';
export const ROUTE_NAME_HYPERPARAMETERS = 'hyperparameters';
export const ROUTE_NAME_USERS = 'users';
export const ROUTE_NAME_FEATURE_FLAGS = 'feature-flags';
export const ROUTE_NAME_ANNOUNCEMENTS = 'announcements';

export const ACCENT_COLOR_1 = '#E4E9F1';
export const ACCENT_COLOR_2 = '#5D7BAC';

export const STEP_CONTENT_WIDTH = 1056;

// RBAC Permissions
export const PERMISSION_ACCESS_INTERNAL_APP = 'access_internal_app';
export const PERMISSION_MANAGE_USERS = 'can_manage_users';
export const PERMISSION_HAS_BILLING_ACCESS = 'has_billing_access';

// User Roles
export const ROLE_USER = 'ROLE_INTERNAL_USER';
export const ROLE_ADMIN = 'ROLE_INTERNAL_ADMIN';
export const ROLE_BILLING_MANAGER = 'ROLE_INTERNAL_BILLING_MANAGER';
export const ROLE_REVIEWER = 'ROLE_INTERNAL_REVIEWER';
