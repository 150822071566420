import { Empty } from 'protos/google/protobuf/empty';
import { Organization } from 'protos/pb/v1alpha1/organization';
import {
  CreateOrganizationRequest,
  DeleteOrganizationRequest,
  OrganizationsClientImpl,
} from 'protos/pb/v1alpha1/organization_service';
import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtils';
import { storageService } from './StorageService';

export class OrganizationsService {
  private static instance: OrganizationsService;
  private static client = new OrganizationsClientImpl(rpcWithErrorHandling);

  public static getInstance(): OrganizationsService {
    if (!this.instance) {
      this.instance = new OrganizationsService();
    }
    return this.instance;
  }

  async createOrganization(
    req: CreateOrganizationRequest,
  ): Promise<Organization> {
    const authorization = await storageService.getAuthorizationHeader();
    return OrganizationsService.client.CreateOrganization(
      req,
      getMetaData({ authorization }),
    );
  }

  async deleteOrganization(req: DeleteOrganizationRequest): Promise<Empty> {
    const authorization = await storageService.getAuthorizationHeader();
    return OrganizationsService.client.DeleteOrganization(
      req,
      getMetaData({ authorization }),
    );
  }
}

export const organizationsService = OrganizationsService.getInstance();
