import { Add, CorporateFare } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Fab,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import CustomPagination from '../../components/core/CustomPagination';
import CustomTableCell from '../../components/core/CustomTableCell';
import CustomTableLabel from '../../components/core/CustomTableLabel';
import CustomTypography, {
  TypographyType,
} from '../../components/core/CustomTypography';
import { CurrentBreadcrumb } from '../../components/core/StyledBreadcrumb';
import StyledTableRow from '../../components/core/StyledTableRow';
import { useStore } from '../../hooks/useStore';
import {
  FeatureFlag,
  ListFeatureFlagsRequest,
} from 'protos/pb/v1alpha2/feature_flag_service';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACCENT_COLOR_1, ELLIPSIS_STYLE } from '../../utils/constants';
import { formatDate } from '../../utils/helpers';

const FeatureFlags: React.FC = () => {
  const navigate = useNavigate();
  const store = useStore();

  const {
    featureFlags,
    totalSize,
    loadingFeatureFlagsList,
    listFeatureFlags,
    setSelectedFeatureFlag,
  } = store.featureFlagStore;

  const [page, setPage] = useState(1);
  const rowsPerPage = 20;
  const pagedFeatureFlags = featureFlags.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage,
  );

  const refreshPage = () => {
    setPage(1);
    const req = ListFeatureFlagsRequest.create({
      pageSize: rowsPerPage,
      page: page,
    });
    listFeatureFlags(req, true);
  };

  useEffect(() => {
    refreshPage();
  }, []);

  const fetchNextFlags = async (nextPage: number) => {
    listFeatureFlags(
      { page: nextPage, pageSize: rowsPerPage },
      false /* Refresh */,
    );
  };

  const onTableRowClick = (featureFlag: FeatureFlag) => {
    navigate(`/feature-flags/${featureFlag.id}/update`);
    setSelectedFeatureFlag(featureFlag);
  };

  const onCreateFeatureFlag = () => {
    navigate('/feature-flags/create');
  };

  return (
    <Box
      sx={{
        margin: '16px',
      }}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <Breadcrumbs separator='>'>
          <CurrentBreadcrumb
            label='Feature Flags'
            icon={<CorporateFare fontSize='small' color='info' />}
          />
        </Breadcrumbs>
        <Fab variant='extended' color='primary' onClick={onCreateFeatureFlag}>
          <Add sx={{ marginRight: '4px' }} />
          Feature Flag
        </Fab>
      </Stack>
      <Box>
        {loadingFeatureFlagsList ? (
          <Box display={'flex'} pt={'60px'} justifyContent={'center'}>
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              marginTop: '30px',
              borderRadius: '10px',
            }}
          >
            <TableContainer
              sx={{
                borderStartStartRadius: 'inherit',
                borderStartEndRadius: 'inherit',
              }}
            >
              <Table
                sx={{
                  tableLayout: 'fixed',
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: ACCENT_COLOR_1,
                    }}
                  >
                    <CustomTableCell title='Name'>
                      <CustomTableLabel label='Name' />
                    </CustomTableCell>
                    <CustomTableCell title='Description'>
                      <CustomTableLabel label='Description' />
                    </CustomTableCell>
                    <CustomTableCell title='Updated Time'>
                      <CustomTableLabel label='Updated Time' />
                    </CustomTableCell>
                    <CustomTableCell title='Updated By'>
                      <CustomTableLabel label='Updated By' />
                    </CustomTableCell>
                    <CustomTableCell title='Default Status'>
                      <CustomTableLabel label='Default Status' />
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pagedFeatureFlags.map((flag, index) => (
                    <StyledTableRow
                      key={flag.name}
                      tabIndex={0}
                      role='row'
                      sx={{
                        backgroundColor: index % 2 === 0 ? 'white' : '#f9f9f9',
                        cursor: 'pointer',
                        ':hover': { backgroundColor: '#1669F74D' },
                      }}
                      onClick={() => onTableRowClick(flag)}
                    >
                      <CustomTableCell>
                        <span title={flag.name ?? 'NO DATA'}>
                          {flag.name ?? 'NO DATA'}
                        </span>
                      </CustomTableCell>
                      <CustomTableCell>
                        <CustomTypography
                          typographyType={TypographyType.Label}
                          sx={{
                            ...ELLIPSIS_STYLE,
                          }}
                        >
                          {flag.description || '-'}
                        </CustomTypography>
                      </CustomTableCell>
                      <CustomTableCell ellipsis={false}>
                        <CustomTypography>
                          {flag.updatedAt ? formatDate(flag.updatedAt) : '-'}
                        </CustomTypography>
                      </CustomTableCell>
                      <CustomTableCell ellipsis={false}>
                        <CustomTypography>
                          {flag.updatedBy || '-'}
                        </CustomTypography>
                      </CustomTableCell>
                      <CustomTableCell ellipsis={false}>
                        <CustomTypography>
                          {flag.rule?.enabled ? 'Enabled' : 'Disabled'}
                        </CustomTypography>
                      </CustomTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <CustomPagination
              rowsPerPage={10}
              totalSize={totalSize}
              page={page - 1}
              onNextPage={(newPage: number) => {
                setPage(newPage + 1);
                if (
                  featureFlags.length < totalSize! &&
                  featureFlags.length < (newPage + 1) * rowsPerPage
                ) {
                  fetchNextFlags(newPage + 1);
                }
              }}
              onPrevPage={(newPage: number) => {
                setPage(newPage + 1);
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(FeatureFlags);
