import { Box, TextField, CSSObject } from '@mui/material';
import React, { FC, memo } from 'react';
import CustomTypography, { TypographyType } from './CustomTypography';
import { ELLIPSIS_STYLE } from '../../utils/constants';

interface CustomTextFieldProps {
  value: string;
  type?: 'text' | 'number' | 'date';
  name?: string;
  error?: boolean;
  helperText?: any;
  onClick?: () => void;
  onKeyUp?: (event: any) => void;
  color?: string;
  label: string;
  size: 'small' | 'medium' | 'large' | 'full';
  disabled?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  extraProps?: any;
  placeholder?: string;
  fontSize?: string;
  multiline?: boolean;
  minCount?: number;
  ariaLabel?: string;
  required?: boolean;
  hasDescription?: boolean;
  autoFocus?: boolean;
  readOnly?: boolean;
  onChange?: (e: any) => void;
  labelStyle?: CSSObject;
  helperTextStyle?: CSSObject;
  ellipsis?: boolean;
  height?: string;
  gapTop?: string | number;
  ariaHidden?: boolean;
}

const CustomTextField: FC<CustomTextFieldProps> = (props) => {
  const {
    value,
    type,
    size,
    error,
    helperText,
    disabled = false,
    label,
    startAdornment,
    endAdornment,
    color,
    onClick,
    extraProps,
    placeholder,
    fontSize,
    multiline,
    minCount,
    ariaLabel,
    required = false,
    name,
    onKeyUp,
    hasDescription,
    autoFocus,
    readOnly,
    onChange,
    labelStyle = {},
    ellipsis,
    height = '23px',
    gapTop = '8px',
    helperTextStyle = {},
    ariaHidden,
  } = props;
  let width = '';

  const inputProps = {
    'aria-label': ariaLabel ?? label,
    'aria-required': required,
    'aria-describedby': hasDescription ? name : undefined,
    'aria-hidden': ariaHidden,
  };
  switch (size) {
    case 'small':
      width = '359px';
      break;
    case 'medium':
      width = '446px';
      break;
    case 'large':
      width = '485px';
      break;
    case 'full':
      width = '100%';
  }

  return (
    <Box
      onClick={onClick}
      onKeyUp={onKeyUp}
      display={'flex'}
      flexDirection={'column'}
    >
      <CustomTypography
        id={label}
        component={'span'}
        typographyType={TypographyType.Label}
        sx={{
          ...labelStyle,
        }}
      >
        {label}
      </CustomTypography>
      <TextField
        onChange={onChange}
        multiline={multiline}
        autoFocus={autoFocus}
        placeholder={placeholder}
        disabled={disabled}
        type={type ?? 'text'}
        variant='outlined'
        value={value}
        size={'small'}
        error={error}
        helperText={helperText}
        {...extraProps}
        InputProps={{
          startAdornment,
          endAdornment,
          inputProps,
          readOnly,
        }}
        title={ellipsis && value}
        sx={{
          width,
          marginTop: gapTop,
          bgcolor: color,
          borderRadius: '4px',
          '.MuiFormHelperText-root': {
            ...helperTextStyle,
          },
          '& .MuiInputBase-root': {
            padding: multiline && '12px',
          },
          '& .MuiOutlinedInput-input  ': {
            lineHeight: '16px',
            height: multiline ? '162px !important' : height,
            fontSize: fontSize ?? '12px',
            fontWeight: 400,
            WebkitTextFillColor: '#000000 !important',
            cursor: disabled ? 'not-allowed' : 'pointer',
            ...(ellipsis && ELLIPSIS_STYLE),
          },
        }}
      />
      {multiline && (
        <CustomTypography
          sx={{ alignSelf: 'flex-end', padding: '4px' }}
          color='#757575'
          typographyType={TypographyType.Label}
        >
          {value.length ? value.length : extraProps?.value.length}/{minCount}
        </CustomTypography>
      )}
    </Box>
  );
};

export default memo(CustomTextField);
