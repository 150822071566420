import { Box, CircularProgress } from '@mui/material';
import { useStore } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import AuthPage from '../../pages/Auth';
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Token, storageService } from '../../services/StorageService';
import OptionsConfig from './OptionsConfig';

const OptionsApp: React.FC = observer(() => {
  const [loadingToken, setLoadingToken] = useState(false);
  const store = useStore();
  const { loggedInUser, selectedOrgInfo, fetchingLoggedInUser } =
    store.userStore;
  const navigate = useNavigate();

  useEffect(() => {
    if (!loadingToken) loadToken();
  }, [loggedInUser]);

  const loadToken = async () => {
    setLoadingToken(true);
    const token: Token | undefined = await storageService.getStoredToken();
    if (loggedInUser === undefined && token !== undefined) {
      await store.userStore.getLoggedInUser();
    } else {
      const currentPath = window.location.pathname;
      if (currentPath && !currentPath.includes('/login')) {
        navigate('/login?redirectUrl=' + currentPath);
      } else if (!currentPath.includes('/login')) {
        navigate('/login');
      }
    }
    setLoadingToken(false);
  };

  if (loadingToken || fetchingLoggedInUser)
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        height={'100%'}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <>
      {loggedInUser && selectedOrgInfo ? (
        <Routes>
          <Route path='/login' element={<Navigate to={'/dashboard'} />} />
          <Route
            path='*'
            element={
              loggedInUser.prerequisite?.policiesToReview ? (
                <h1>Policy Terms</h1>
              ) : (
                <OptionsConfig />
              )
            }
          ></Route>
        </Routes>
      ) : (
        <Routes>
          <Route path='*' element={<AuthPage />} />
        </Routes>
      )}
    </>
  );
});

export default OptionsApp;
