import { Box, StepIconProps, SxProps, Theme } from '@mui/material';
import React, { FC, memo } from 'react';
import { STEP_CONTENT_WIDTH } from '../../../../utils/constants';
import StepIcon from './StepIcon';
import CustomTypography from '../../../../components/core/CustomTypography';

interface HyperparameterDetailStepProps extends StepIconProps {
  title: string;
  iconColor: string;
  background: string;
  sx: SxProps<Theme> | undefined;
  ariaLabel?: string;
}

const HyperparameterDetailStep: FC<HyperparameterDetailStepProps> = (props) => {
  const { iconColor, title, background, sx, ariaLabel } = props;

  return (
    <Box
      bgcolor={background}
      height={'48px'}
      display={'flex'}
      width={STEP_CONTENT_WIDTH}
      maxWidth={STEP_CONTENT_WIDTH}
      sx={sx}
    >
      <StepIcon bgColor={iconColor} color={'white'} />
      <CustomTypography
        ariaLabel={ariaLabel}
        component={'span'}
        role='definition'
        color='#000000'
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: 3,
          flex: 1,
        }}
      >
        {title}
      </CustomTypography>
    </Box>
  );
};

export default memo(HyperparameterDetailStep);
