import { Box } from '@mui/material';
import React, { memo } from 'react';
import CustomTypography, { TypographyType } from './CustomTypography';
interface CustomTableLabelProps {
  label: string;
}
const CustomTableLabel: React.FC<CustomTableLabelProps> = ({ label }) => {
  return (
    <Box display={'flex'} alignItems={'center'} gap={1}>
      <CustomTypography typographyType={TypographyType.Header4} color='#4B414E'>
        {label}
      </CustomTypography>
    </Box>
  );
};

export default memo(CustomTableLabel);
