import {
  ListWorkflowsRequest,
  ListWorkflowsResponse,
  WorkflowsClientImpl,
} from 'protos/pb/v1alpha2/workflows_service';
import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtils';
import { storageService } from './StorageService';

export class WorkflowService {
  private static instance: WorkflowService;
  private static client = new WorkflowsClientImpl(rpcWithErrorHandling);

  public static getInstance(): WorkflowService {
    if (!this.instance) {
      this.instance = new WorkflowService();
    }
    return this.instance;
  }

  async listWorkflows(
    req: ListWorkflowsRequest,
  ): Promise<{ response?: ListWorkflowsResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await WorkflowService.client.ListWorkflows(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }
}

export const workflowService = WorkflowService.getInstance();
