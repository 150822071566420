import { grpc } from '@improbable-eng/grpc-web';
import { useStore } from '../hooks/useStore';
import {
  GetNewGoogleTokenRequest,
  GetNewGoogleTokenResponse,
} from 'protos/pb/v1alpha1/tokens_service';
import { LoginResponse } from 'protos/pb/v1alpha1/users_service';
import { eraseCookie, setCookie } from '../utils/Cookie';
import { authService } from './AuthService';

export interface Token {
  sessionId: string | undefined;
  accessToken: string | undefined;
  accessTokenExpiresAt: number | undefined;
  refreshToken: string | undefined;
  refreshTokenExpiresAt: number | undefined;
  platform?: string;
}

export interface GoogleToken {
  accessToken: string;
  accessTokenExpiresAt: Date;
}

export const convertToken = (pbToken: LoginResponse): Token => {
  return {
    sessionId: pbToken.sessionId,
    accessToken: pbToken.accessToken,
    accessTokenExpiresAt:
      pbToken.accessTokenExpiresAt &&
      new Date(pbToken.accessTokenExpiresAt)?.getTime(),
    refreshToken: pbToken.refreshToken,
    refreshTokenExpiresAt:
      pbToken.refreshTokenExpiresAt &&
      new Date(pbToken.refreshTokenExpiresAt)?.getTime(),
  };
};

export class StorageService {
  private static instance: StorageService;

  public static getInstance(): StorageService {
    if (!this.instance) {
      this.instance = new StorageService();
    }
    return this.instance;
  }

  setStoredToken(token: Token): Promise<void> {
    if (JSON.stringify(token) === '{}') {
      throw Error('Empty token is being stored.');
    }
    return new Promise((resolve) => {
      const tokenSting = JSON.stringify(token);
      localStorage.setItem('token', tokenSting);
      setCookie('token', tokenSting, 1);
      resolve();
    });
  }

  setStoredGoogleToken(token: GoogleToken): Promise<void> {
    if (JSON.stringify(token) === '{}') {
      throw Error('Empty token is being stored.');
    }
    return new Promise((resolve) => {
      const tokenSting = JSON.stringify(token);
      localStorage.setItem('google-token', tokenSting);
      resolve();
    });
  }

  getStoredGoogleToken(email: string): Promise<GoogleToken> {
    return new Promise((resolve) => {
      const item = localStorage.getItem('google-token');
      const itemToken: GoogleToken = item && JSON.parse(item);
      // 10*1000 is ten seconds, so if the access token will expire in 10 seconds , we deem it expired
      if (
        new Date(itemToken.accessTokenExpiresAt) <
        new Date(new Date().getTime() + 10 * 1000)
      ) {
        authService
          .registerGoogleToken({ email } as GetNewGoogleTokenRequest)
          .then((res: GetNewGoogleTokenResponse) => {
            resolve({
              accessToken: res.accessToken as string,
              accessTokenExpiresAt: res.accessTokenExpiresAt!,
            });
          })
          .catch(() => resolve(itemToken));
      } else {
        resolve(itemToken);
      }
    });
  }

  setStoredOrgResourceName(orgResourceName: string): Promise<void> {
    return new Promise((resolve) => {
      localStorage.setItem('org-resource-name', orgResourceName);
      resolve();
    });
  }

  getStoredOrgResourceName(): Promise<string | null> {
    return new Promise((resolve) => {
      const item = localStorage.getItem('org-resource-name');
      resolve(item);
    });
  }

  getStoredToken(refreshToken = true): Promise<Token | undefined> {
    return new Promise((resolve) => {
      const item = localStorage.getItem('token');
      if (item) {
        const itemToken: Token = JSON.parse(item);
        if (refreshToken) {
          authService
            .refreshTokenIfNeeded(itemToken)
            .then((token: Token) => {
              resolve(token);
            })
            .catch(() => resolve(undefined));
        } else {
          resolve(itemToken);
        }
      } else {
        resolve(undefined);
      }
    });
  }

  deleteStoredValues(): Promise<void> {
    return new Promise((resolve) => {
      localStorage.clear();
      eraseCookie('token');
      resolve();
    });
  }

  setStoredEnableEventUpload(enable_event_upload: boolean): Promise<void> {
    return new Promise((resolve) => {
      localStorage.setItem(
        'enable_event_upload',
        enable_event_upload.toString(),
      );
      resolve();
    });
  }

  getStoredEnabledEventUpload(): Promise<boolean> {
    return new Promise((resolve) => {
      const item = localStorage.getItem('enable_event_upload');
      resolve(item === 'true');
    });
  }

  getAuthorizationHeader(): Promise<string> {
    return new Promise((resolve, reject) => {
      StorageService.instance
        .getStoredToken()
        .then((token: Token | undefined) => {
          if (token) {
            resolve(`Bearer ${token?.accessToken}`);
          } else {
            StorageService.instance.deleteStoredValues();
            const rootStore = useStore();
            rootStore.authStore.resetUserAndToken();
            reject();
          }
        });
    });
  }

  async getMetadata(): Promise<grpc.Metadata> {
    const authHeader = await this.getAuthorizationHeader();
    if (authHeader) {
      return new grpc.Metadata({ authorization: authHeader });
    } else {
      return new grpc.Metadata();
    }
  }

  setStoredGoogleScope(scopes: string): Promise<void> {
    return new Promise((resolve) => {
      localStorage.setItem('google-scopes', scopes);
      resolve();
    });
  }

  getStoredGoogleScope(): Promise<string | null> {
    return new Promise((resolve) => {
      const item = localStorage.getItem('google-scopes');
      resolve(item);
    });
  }
}

export const storageService = StorageService.getInstance();
