import { SxProps, Theme, Typography } from '@mui/material';
import React, { ElementType } from 'react';

interface Props {
  children: React.ReactNode;
  typographyType?: TypographyType;
  color?: string;
  sx?: SxProps<Theme>;
  weight?: number;
  height?: number;
  size?: string;
  lineHeight?: string;
  onClick?: () => void;
  component?: ElementType<any>;
  id?: string;
  tabIndex?: number;
  ariaLabel?: string;
  role?: string;
}

const CustomTypography: React.FC<Props> = (props) => {
  const {
    children,
    typographyType,
    color,
    sx,
    weight,
    height,
    size,
    lineHeight,
    onClick,
    component,
    id,
    tabIndex,
    ariaLabel,
    role,
  } = props;

  const getFontWeight = () => {
    if (weight) {
      return weight;
    }
    switch (typographyType) {
      case TypographyType.Header2:
      case TypographyType.Header3:
      case TypographyType.Header4:
        return 600;
      case TypographyType.Header5:
        return 600;
      case TypographyType.MediumPara:
      case TypographyType.Label:
        return 400;
      case TypographyType.Bolder:
        return 700;
      default:
        return 400;
    }
  };

  const getFontSize = () => {
    if (size) {
      return size;
    }
    switch (typographyType) {
      case TypographyType.Header2:
        return '24px';
      case TypographyType.Label:
        return '12px';
      case TypographyType.Bolder:
        return '36px';
      case TypographyType.Header4:
      case TypographyType.MediumPara:
        return '16px';
      case TypographyType.Header5:
        return '14px';
      case TypographyType.Header3:
        return '20px';
      default:
        return '14px';
    }
  };

  const getLineHeight = () => {
    if (lineHeight) {
      return lineHeight;
    }
    switch (typographyType) {
      case TypographyType.Header2:
        return '30px';
      case TypographyType.Label:
        return '16px';
      case TypographyType.Bolder:
        return '43.57px';
      case TypographyType.Header4:
      case TypographyType.MediumPara:
        return '20px';
      case TypographyType.Header5:
        return '16px';
      case TypographyType.Header3:
        return '24px';
      default:
        return '18px';
    }
  };

  return (
    <Typography
      id={id}
      role={role}
      fontWeight={getFontWeight()}
      fontSize={getFontSize()}
      lineHeight={getLineHeight()}
      color={color}
      sx={sx}
      height={height}
      onClick={onClick}
      component={component ?? 'p'}
      tabIndex={tabIndex}
      aria-label={ariaLabel}
    >
      {children}
    </Typography>
  );
};

export enum TypographyType {
  Header2,
  Label,
  Bolder,
  Header4,
  Header3,
  MediumPara,
  Header5,
}

export default React.memo(CustomTypography);
