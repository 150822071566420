import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';
import CustomPagination from '../../../components/core/CustomPagination';
import CustomTableCell from '../../../components/core/CustomTableCell';
import CustomTableLabel from '../../../components/core/CustomTableLabel';
import StyledTableRow from '../../../components/core/StyledTableRow';
import { useStore } from '../../../hooks/useStore';
import { observer } from 'mobx-react';
import { ListHyperparametersRequest } from 'protos/pb/orby_internal/orby_internal_service';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toastService } from '../../../services/ToastService';
import { ACCENT_COLOR_1 } from '../../../utils/constants';

const HyperparametersList: React.FC = observer(() => {
  const store = useStore();
  const navigate = useNavigate();
  const {
    loadingHyperparameters,
    hyperparameters,
    hyperparametersError,
    totalHyperparametersCount,
    getHyperparametersList,
    resetHyperparametersError,
  } = store.internalAppStore;

  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const pagedData = hyperparameters?.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage,
  );
  const [displayNamePrefix, setDisplayNamePrefix] = useState('');

  useEffect(() => {
    refreshPage();
  }, []);

  useEffect(() => {
    refreshPage();
  }, [displayNamePrefix]);

  const refreshPage = () => {
    setPage(1);
    const req: ListHyperparametersRequest = {
      pageNumber: page,
      pageSize: rowsPerPage,
    };
    let filterStr = '';
    if (displayNamePrefix) {
      filterStr = `display_name_prefix=${displayNamePrefix}`;
    }
    req.filter = filterStr;
    getHyperparametersList(req, true);
  };

  useEffect(() => {
    if (hyperparametersError) {
      toastService.showError(
        `Failed to fetch hyperparameters: ${hyperparametersError}`,
      );
      resetHyperparametersError();
    }
  }, [hyperparametersError]);

  const fetchNextHyperparameters = async (nextPage: number) => {
    getHyperparametersList(
      { pageNumber: nextPage, pageSize: rowsPerPage },
      false /* Refresh */,
    );
  };

  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'flex-start'}
        width={'100%'}
        gap={'8px'}
        marginTop={'24px'}
        marginBottom={'24px'}
      >
        <Box>
          <TextField
            variant='outlined'
            value={displayNamePrefix}
            size={'small'}
            InputProps={{
              startAdornment: <GridSearchIcon fontSize='medium' />,
              sx: {
                '& .MuiOutlinedInput-input  ': {
                  WebkitTextFillColor: '#000000 !important',
                  height: '23px',
                  lineHeight: '16px',
                },
              },
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setDisplayNamePrefix(event.target.value)
            }
          />
        </Box>
      </Box>

      {loadingHyperparameters ? (
        <Box display={'flex'} pt={'60px'} justifyContent={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            marginTop: '30px',
            borderRadius: '10px',
          }}
        >
          <TableContainer
            sx={{
              borderStartStartRadius: 'inherit',
              borderStartEndRadius: 'inherit',
            }}
          >
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: ACCENT_COLOR_1,
                  }}
                >
                  <CustomTableCell title='Hyperparameter Name'>
                    <CustomTableLabel label='Hyperparameter Name' />
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pagedData.map((t, index) => (
                  <StyledTableRow
                    key={t.name}
                    tabIndex={0}
                    role='row'
                    sx={{
                      backgroundColor: index % 2 === 0 ? 'white' : '#f9f9f9',
                      cursor: 'pointer',
                      ':hover': { backgroundColor: '#1669F74D' },
                    }}
                    onClick={() => {
                      navigate(`${t.name?.split('/').pop()}`);
                    }}
                  >
                    <CustomTableCell>
                      <span title={t.displayName ?? ''}>
                        {t.displayName ?? ''}
                      </span>
                    </CustomTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            rowsPerPage={10}
            totalSize={totalHyperparametersCount}
            page={page - 1}
            onNextPage={(newPage: number) => {
              setPage(newPage + 1);
              if (
                hyperparameters.length < totalHyperparametersCount! &&
                hyperparameters.length < (newPage + 1) * rowsPerPage
              ) {
                fetchNextHyperparameters(newPage + 1);
              }
            }}
            onPrevPage={(newPage: number) => {
              setPage(newPage + 1);
            }}
          />
        </Box>
      )}
    </>
  );
});

export default HyperparametersList;
