import { Close } from '@mui/icons-material';
import {
  Button,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import CustomTextField from '../../../components/core/CustomTextField';
import { useStore } from '../../../hooks/useStore';
import { observer } from 'mobx-react';
import { WorkflowTemplateType } from 'protos/common/workflow_common';
import { Organization } from 'protos/pb/v1alpha1/organization';
import React, { useState } from 'react';
import { toastService } from '../../../services/ToastService';
import WorkflowTemplatesSelector from '../CreateOrganization/components/WorkflowTemplatesSelector';

const EditOrganization: React.FC<{
  onClose: () => void;
  selectedOrganization: Organization;
}> = observer(({ onClose, selectedOrganization }) => {
  const store = useStore();
  const { updateOrganization } = store.internalAppStore;
  const [selectedTemplates, setSelectedTemplates] = useState<
    WorkflowTemplateType[]
  >(selectedOrganization.workflowTemplateTypes ?? []);
  const isButtonEnabled = selectedTemplates.length > 0;

  const handleUpdateOrganization = () => {
    selectedOrganization = {
      ...selectedOrganization,
      workflowTemplateTypes: selectedTemplates,
    };

    updateOrganization(selectedOrganization)
      .then(() => {
        setSelectedTemplates([]);
        toastService.showSuccess('Organization updated successfully');
        onClose();
      })
      .catch((e) => {
        toastService.showError(`Failed to update organization: ${e}`);
        onClose();
      });
  };

  return (
    <Grid container spacing={2} sx={{ padding: '16px' }}>
      <DialogTitle>Update Organization</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'black',
        }}
      >
        <Close />
      </IconButton>
      <Grid item xs={12}>
        <CustomTextField
          label='Organization name'
          size='small'
          value={selectedOrganization.displayName ?? ''}
          color={'#DDDBDA'}
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <WorkflowTemplatesSelector
          selectedTemplates={selectedTemplates}
          setSelectedTemplates={setSelectedTemplates}
        />
      </Grid>
      <DialogActions
        sx={{
          justifyContent: 'end',
          width: '100%',
        }}
      >
        <Button
          variant='contained'
          color='primary'
          onClick={handleUpdateOrganization}
          disabled={!isButtonEnabled}
        >
          Submit
        </Button>
      </DialogActions>
    </Grid>
  );
});

export default EditOrganization;
