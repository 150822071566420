import { Close } from '@mui/icons-material';
import {
  Button,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useStore } from '../../../../hooks/useStore';
import { observer } from 'mobx-react';
import { GetPredictionAnalysisRequest } from 'protos/pb/orby_internal/orby_internal_service';
import React, { useState } from 'react';
import { toastService } from '../../../../services/ToastService';

const WorkflowAnalysisForm: React.FC<{
  workflowName: string;
  onClose: () => void;
}> = observer(({ workflowName, onClose }) => {
  const store = useStore();
  const { getPredictionAnalysis } = store.internalAppStore;
  const [dateRange, setDateRange] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: null,
    endDate: null,
  });

  const handleSubmit = () => {
    if (
      dateRange.startDate &&
      dateRange.endDate &&
      dateRange.startDate > dateRange.endDate
    ) {
      toastService.showError('Start date cannot be after end date');
      return;
    }

    const req: GetPredictionAnalysisRequest = {
      workflowNames: [workflowName],
    };
    if (dateRange.startDate && dateRange.endDate) {
      req.startDate = new Date(dateRange.startDate) || undefined;
      req.endDate = new Date(dateRange.endDate) || undefined;
    }

    toastService.showInfo(
      'Generating Prediction Analysis. The report will be downloaded once ready.',
    );

    getPredictionAnalysis(req).then(() => {
      onClose();
    });
  };

  return (
    <Grid container spacing={2} sx={{ padding: '16px' }}>
      <DialogTitle>Download Workflow Analysis</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'black',
        }}
      >
        <Close />
      </IconButton>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid item xs={6}>
          <DatePicker
            label='Start Date'
            value={dateRange.startDate}
            onChange={(newValue) => {
              setDateRange({ ...dateRange, startDate: newValue });
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label='End Date'
            value={dateRange.endDate}
            onChange={(newValue) => {
              setDateRange({ ...dateRange, endDate: newValue });
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
      </LocalizationProvider>

      <DialogActions
        sx={{
          justifyContent: 'end',
          width: '100%',
        }}
      >
        <Button variant='contained' color='primary' onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Grid>
  );
});

export default WorkflowAnalysisForm;
