import { Close } from '@mui/icons-material';
import {
  Button,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import { useStore } from '../../../hooks/useStore';
import { observer } from 'mobx-react';
import { Organization } from 'protos/pb/v1alpha1/organization';
import { Hyperparameter } from 'protos/common/hyperparameter';
import React, { useState } from 'react';
import { toastService } from '../../../services/ToastService';
import HyperparameterSelector from './HyperparameterSelector';

const AssignHyperparameterToOrg: React.FC<{
  org: Organization;
  onClose: () => void;
}> = observer(({ org, onClose }) => {
  const store = useStore();
  const { assignHyperparameterToOrg } = store.internalAppStore;
  const [selectedHyperparameter, setSelectedHyperparameter] =
    useState<Hyperparameter>({});
  const isButtonDisabled = (selectedHyperparameter.name ?? '').length === 0;

  const handleSubmit = () => {
    if (selectedHyperparameter.name === '') {
      // Unassign hyperparameter. Do not send empty string to backend.
      setSelectedHyperparameter({});
    }
    assignHyperparameterToOrg(org.name!, selectedHyperparameter.name).then(
      (err) => {
        if (err) {
          toastService.showError(
            `Failed to ${selectedHyperparameter.name === undefined ? 'unassign' : 'assign'} hyperparameter: ${err}`,
          );
          return;
        }
        toastService.showSuccess(
          `Hyperparameter ${selectedHyperparameter.name === undefined ? 'unassigned' : 'assigned'} successfully`,
        );
        onClose();
      },
    );
  };

  return (
    <Grid container spacing={2} sx={{ padding: '16px' }}>
      <DialogTitle>Assign Hyperparameter to Org</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'black',
        }}
      >
        <Close />
      </IconButton>
      <Grid item xs={12}>
        <HyperparameterSelector
          selectedValue={selectedHyperparameter}
          setSelectedValue={setSelectedHyperparameter}
        />
      </Grid>

      <DialogActions
        sx={{
          justifyContent: 'end',
          width: '100%',
        }}
      >
        <Button variant='text' color='primary' onClick={handleSubmit}>
          Unassign Hyperparameter
        </Button>

        <Button
          variant='contained'
          color='primary'
          disabled={isButtonDisabled}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Grid>
  );
});

export default AssignHyperparameterToOrg;
