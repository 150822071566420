import { Group } from '@mui/icons-material';
import { Box, Breadcrumbs, Stack } from '@mui/material';
import { CurrentBreadcrumb } from '../../components/core/StyledBreadcrumb';
import React from 'react';
import UsersList from './components/UsersList';

const Users: React.FC = () => {
  return (
    <Box
      sx={{
        margin: '16px',
      }}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <Breadcrumbs separator='>'>
          <CurrentBreadcrumb
            label='Users'
            icon={<Group fontSize='small' color='info' />}
          />
        </Breadcrumbs>
      </Stack>
      <Box>
        <UsersList />
      </Box>
    </Box>
  );
};

export default Users;
