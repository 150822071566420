import { Box } from '@mui/material';
import WebDrawerWrapper from '../../components/WebDrawer/WebDrawerWrapper';
import { useStore } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import FeatureFlagCreationPage from '../../pages/FeatureFlags/FeatureFlagCreationPage';
import FeatureFlags from '../../pages/FeatureFlags/FeatureFlags';
import FeatureFlagUpdatePage from '../../pages/FeatureFlags/FeatureFlagUpdatePage';
import HyperparameterDetail from '../../pages/Hyperparameters/HyperparameterDetail/HyperparameterDetail';
import Hyperparameters from '../../pages/Hyperparameters/Hyperparameters';
import OrganizationDetails from '../../pages/Organizations/OrganizationDetails/OrganizationDetails';
import Organizations from '../../pages/Organizations/Organizations';
import Users from '../../pages/Users/Users';
import React, { Suspense, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Announcements from '../../pages/Announcements/Announcements';
import AnnouncementDetailPage from '../../pages/Announcements/AnnouncementDetailPage';
import {
  PERMISSION_MANAGE_USERS,
  ROUTE_NAME_FEATURE_FLAGS,
  ROUTE_NAME_HYPERPARAMETERS,
  ROUTE_NAME_ORGANIZATIONS,
  ROUTE_NAME_USERS,
  ROUTE_NAME_ANNOUNCEMENTS,
} from '../../utils/constants';

const OptionsConfig: React.FC = observer(() => {
  const location = useLocation();
  const [collapse, setCollapse] = useState(
    !!location.pathname.includes('automation-review'),
  );
  const store = useStore();
  const { user } = store.authStore;
  const isUserAdmin =
    user?.permittedActions?.includes(PERMISSION_MANAGE_USERS) ?? false;

  return (
    <Box>
      <title>`&quot;Login | Orby-UI&quot;</title>
      <Box display={'flex'}>
        <WebDrawerWrapper collapse={collapse} setCollapse={setCollapse} />
        <Box
          id={'main-content'}
          sx={{
            width: `calc(100% - ${collapse ? '80px' : '280px'})`,
            marginLeft: collapse ? '80px' : '280px',
            transition: 'width 0.3s ease, margin-left 0.3s ease',
          }}
        >
          <Suspense fallback={<span>Loading</span>}>
            <Routes>
              <Route
                path='/'
                element={<Navigate to={ROUTE_NAME_ORGANIZATIONS} />}
              />
              <Route
                path={`/${ROUTE_NAME_ORGANIZATIONS}/:organizationId/*`}
                element={<OrganizationDetails />}
              />
              <Route
                path={`/${ROUTE_NAME_ORGANIZATIONS}/*`}
                element={<Organizations />}
              />
              <Route
                path={`/${ROUTE_NAME_HYPERPARAMETERS}/:hyperparameterId/*`}
                element={<HyperparameterDetail />}
              />
              <Route
                path={`/${ROUTE_NAME_HYPERPARAMETERS}/*`}
                element={<Hyperparameters />}
              />
              <Route
                path={`/${ROUTE_NAME_USERS}`}
                element={
                  isUserAdmin ? (
                    <Users />
                  ) : (
                    // Do not show users page if logged in user is not admin
                    <Navigate to={ROUTE_NAME_ORGANIZATIONS} />
                  )
                }
              />
              <Route
                path={`/${ROUTE_NAME_FEATURE_FLAGS}`}
                element={
                  isUserAdmin ? (
                    <FeatureFlags />
                  ) : (
                    <Navigate to={ROUTE_NAME_ORGANIZATIONS} />
                  )
                }
              />
              <Route
                path={`/${ROUTE_NAME_FEATURE_FLAGS}/create`}
                element={
                  isUserAdmin ? (
                    <FeatureFlagCreationPage />
                  ) : (
                    <Navigate to={ROUTE_NAME_ORGANIZATIONS} />
                  )
                }
              />
              <Route
                path={`/${ROUTE_NAME_FEATURE_FLAGS}/:feature_flag_id/update`}
                element={
                  isUserAdmin ? (
                    <FeatureFlagUpdatePage />
                  ) : (
                    <Navigate to={ROUTE_NAME_ORGANIZATIONS} />
                  )
                }
              />
              <Route
                path={`/${ROUTE_NAME_ANNOUNCEMENTS}`}
                element={
                  isUserAdmin ? (
                    <Announcements />
                  ) : (
                    <Navigate to={ROUTE_NAME_ORGANIZATIONS} />
                  )
                }
              />
              <Route
                path={`/${ROUTE_NAME_ANNOUNCEMENTS}/:announcementId`}
                element={
                  isUserAdmin ? (
                    <AnnouncementDetailPage />
                  ) : (
                    <Navigate to={ROUTE_NAME_ORGANIZATIONS} />
                  )
                }
              />
              <Route
                path='*'
                element={<Navigate to={ROUTE_NAME_ORGANIZATIONS} />}
              />
            </Routes>
          </Suspense>
        </Box>
      </Box>
    </Box>
  );
});

export default OptionsConfig;
