import { Backdrop, Box, CircularProgress, Container } from '@mui/material';
import { LoginForm } from '../../components/Auth/LoginForm';
import { useStore } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import { LogoutRequest } from 'protos/pb/v1alpha1/users_service';
import React, { useEffect, useState } from 'react';
import { storageService } from '../../services/StorageService';
import { toastService } from '../../services/ToastService';
import OrbyLoginLogo from '../../static/orby-logo-main.svg';
import OrbyBackground from '../../static/login/orby-bg.svg';

const LoginPage: React.FC = observer(() => {
  const store = useStore();
  const { selectedOrgInfo, loggedInUser } = store.userStore;
  const { error, logoutUser, isLoading } = store.authStore;

  const [loggingOut, setLoggingOut] = useState(false);

  const handleLogout = async () => {
    const token = await storageService.getStoredToken();
    const req: LogoutRequest = {};
    if (token) {
      req.name = token.sessionId as string;
    }
    logoutUser(req);
  };

  useEffect(() => {
    if (error) {
      toastService.showWarning(error);
    }
  }, [error]);

  useEffect(() => {
    if (loggedInUser && !selectedOrgInfo && !loggingOut) {
      setTimeout(() => {
        setLoggingOut(true);
        handleLogout();
      }, 5000);
    }
  }, [loggedInUser, selectedOrgInfo, loggingOut]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          minWidth: '1400px',
          background: 'linear-gradient(63.57deg, #3E1C96 0%, #6941C6 99.43%)',
          paddingLeft: 0,
          paddingRight: 0,
          overflow: 'scroll',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            maxWidth: '1200px',
            minWidth: '1200px',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '788px',
            background:
              'linear-gradient(180deg, rgba(255, 255, 255, 0.96) 0%, rgba(249, 245, 255, 0.96) 100%)',
            borderRadius: '10px',
          }}
        >
          <Container
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            {/* LEFT SIDE IMAGE BACKGROUND */}
            <Container
              sx={{
                borderRadius: '10px',
                backgroundImage: `url(${OrbyBackground})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '100%',
                paddingLeft: '60px!important',
                paddingTop: '60px',
                display: 'flex',
                flex: 1,
              }}
            >
              <Box marginBottom={4} sx={{ width: '100%' }}>
                <img src={OrbyLoginLogo} alt='Orby Logo' />
              </Box>
            </Container>
            {/* RIGHT SIDE LOGIN FORM */}
            <LoginForm />
          </Container>
        </Box>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  );
});

export default React.memo(LoginPage);
