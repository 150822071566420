import { Box } from '@mui/material';
import CustomTypography from '../core/CustomTypography';
import React, { FC, memo } from 'react';
import { DrawerTabType } from './tabs';

type DrawerTabProps = {
  index: number;
  data: DrawerTabType;
  isSelected: boolean;
  onClick: (arg: string) => void;
  collapse: boolean;
};

const DrawerTab: FC<DrawerTabProps> = ({
  index,
  data,
  isSelected,
  onClick,
  collapse,
}) => {
  const { icon, route, title, description } = data;
  return (
    <Box
      key={index}
      id={route}
      tabIndex={0}
      role={'tab'}
      display='flex'
      alignItems='center'
      onClick={() => onClick(route)}
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          onClick(route);
        }
      }}
      marginBottom='16px'
      bgcolor={isSelected ? '#354051' : '#031026'}
      width={collapse ? '55px' : '230px'}
      height='40px'
      borderRadius='5px'
      boxShadow={isSelected ? '0px 6px 6px rgba(0, 0, 0, 0.25)' : 'none'}
      sx={{
        outlineColor: 'red',
        cursor: 'pointer',
        transition:
          'width 0.3s ease, background-color 0.3s ease, opacity 0.3s ease',
        ':hover': {
          boxShadow: isSelected ? '0px 6px 6px rgba(0, 0, 0, 0.25)' : 'none',
        },
      }}
    >
      <Box marginRight={1} />
      <Box
        title={title}
        sx={{
          color: '#FFFFFF',
          marginLeft: '3px',
          marginRight: '10px',
          marginBottom: '5px',
          marginTop: '12px',
          transition: 'margin 0.3s ease',
        }}
      >
        {icon}
      </Box>
      {!collapse && (
        <Box
          sx={{
            marginBottom: '10px',
            marginTop: '11px',
            transition: 'margin 0.3s ease, opacity 0.3s ease',
          }}
          display={'flex'}
          flexDirection='column'
        >
          <CustomTypography weight={isSelected ? 600 : 400} color={'#FFFFFF'}>
            {title}
          </CustomTypography>
          {description && (
            <CustomTypography size={'10px'} weight={400} color={'gray'}>
              {description}
            </CustomTypography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default memo(DrawerTab);
