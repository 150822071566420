export const palette = {
  primary: {
    main: '#1669F7',
    light: '#1669F74D',
  },
  secondary: {
    main: '#43C356',
    light: '#66BB6A',
  },
  info: {
    main: '#FFFFFF',
    light: '#FFFFFF',
  },
  text: {
    primary: '#000000DE',
    secondary: '#000000A6',
  },
};
