import {
  CreateHyperparameterRequest,
  CreateUserRequest,
  CreateUserResponse,
  GenerateBillingReportRequest,
  GenerateBillingReportResponse,
  GetHyperparameterRequest,
  GetHyperparameterResponse,
  GetPredictionAnalysisRequest,
  GetPredictionAnalysisResponse,
  GetUserPermissionsRequest,
  GetUserPermissionsResponse,
  ListHyperparametersRequest,
  ListHyperparametersResponse,
  ListOrganizationsRequest,
  ListOrganizationsResponse,
  ListUsersRequest,
  ListUsersResponse,
  ListWorkflowsForOrganizationRequest,
  ListWorkflowsForOrganizationResponse,
  OrbyInternalServiceClientImpl,
  UpdateHyperparameterRequest,
  UpdateUserRequest,
  UpdateUserResponse,
} from 'protos/pb/orby_internal/orby_internal_service';
import { UpdateOrganizationRequest } from 'protos/pb/v1alpha1/organization_service';
import { UpdateWorkflowRequest } from 'protos/pb/v1alpha2/workflows_service';
import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtils';
import { storageService } from './StorageService';

export class OrbyInternalAppService {
  private static instance: OrbyInternalAppService;
  private static client = new OrbyInternalServiceClientImpl(
    rpcWithErrorHandling,
  );

  public static getInstance(): OrbyInternalAppService {
    if (!this.instance) {
      this.instance = new OrbyInternalAppService();
    }
    return this.instance;
  }

  async getOrganizations(
    req: ListOrganizationsRequest,
  ): Promise<{ response?: ListOrganizationsResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrbyInternalAppService.client.ListOrganizations(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getHyperparameters(
    req: ListHyperparametersRequest,
  ): Promise<{ response?: ListHyperparametersResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrbyInternalAppService.client.ListHyperparameters(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getWorkflowsForOrganization(
    req: ListWorkflowsForOrganizationRequest,
  ): Promise<{
    response?: ListWorkflowsForOrganizationResponse;
    error?: Error;
  }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response =
        await OrbyInternalAppService.client.ListWorkflowsForOrganization(
          req,
          getMetaData({ authorization }),
        );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async generateBillingReport(req: GenerateBillingReportRequest): Promise<{
    response?: GenerateBillingReportResponse;
    error?: Error;
  }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response =
        await OrbyInternalAppService.client.GenerateBillingReport(
          req,
          getMetaData({ authorization }),
        );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getPredictionAnalysis(req: GetPredictionAnalysisRequest): Promise<{
    response?: GetPredictionAnalysisResponse;
    error?: Error;
  }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response =
        await OrbyInternalAppService.client.GetPredictionAnalysis(
          req,
          getMetaData({ authorization }),
        );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async createUser(req: CreateUserRequest): Promise<{
    response?: CreateUserResponse;
    error?: Error;
  }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrbyInternalAppService.client.CreateUser(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async updateUser(req: UpdateUserRequest): Promise<{
    response?: UpdateUserResponse;
    error?: Error;
  }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrbyInternalAppService.client.UpdateUser(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async createHyperparameter(
    req: CreateHyperparameterRequest,
  ): Promise<{ error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      await OrbyInternalAppService.client.CreateHyperparameter(
        req,
        getMetaData({ authorization }),
      );
      return {};
    } catch (error) {
      return { error: error as Error };
    }
  }

  async updateOrganization(
    req: UpdateOrganizationRequest,
  ): Promise<{ error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      await OrbyInternalAppService.client.UpdateOrganizationInInternalApp(
        req,
        getMetaData({ authorization }),
      );
      return {};
    } catch (error) {
      return { error: error as Error };
    }
  }

  async updateWorkflow(req: UpdateWorkflowRequest): Promise<{ error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      await OrbyInternalAppService.client.UpdateWorkflowInInternalApp(
        req,
        getMetaData({ authorization }),
      );
      return {};
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getHyperparameterDetails(req: GetHyperparameterRequest): Promise<{
    resp?: GetHyperparameterResponse;
    error?: Error;
  }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrbyInternalAppService.client.GetHyperparameter(
        req,
        getMetaData({ authorization }),
      );
      return { resp: response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async updateHyperparameter(req: UpdateHyperparameterRequest): Promise<{
    error?: Error;
  }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      await OrbyInternalAppService.client.UpdateHyperparameter(
        req,
        getMetaData({ authorization }),
      );
      return {};
    } catch (error) {
      return { error: error as Error };
    }
  }

  async updateUserRole(req: UpdateUserRequest): Promise<{ error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      await OrbyInternalAppService.client.UpdateUser(
        req,
        getMetaData({ authorization }),
      );
      return {};
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getUsers(
    req: ListUsersRequest,
  ): Promise<{ response?: ListUsersResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrbyInternalAppService.client.ListUsers(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getUserPermissions(
    req: GetUserPermissionsRequest,
  ): Promise<{ response?: GetUserPermissionsResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await OrbyInternalAppService.client.GetUserPermissions(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }
}

export const orbyInternalService = OrbyInternalAppService.getInstance();
