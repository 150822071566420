const OrbyColorPalette = {
  // WHITE
  'white-0': '#FFFFFF',
  // BLACK
  'black-0': '#000000',
  // GREY
  'grey-25': '#FCFCFD',
  'grey-50': '#F9FAFB',
  'grey-100': '#F2F4F7',
  'grey-200': '#EAECF0',
  'grey-250': '#E2E2E2',
  'grey-300': '#D0D5DD',
  'grey-400': '#98A2B3',
  'grey-500': '#667085',
  'grey-600': '#475467',
  'grey-700': '#344054',
  'grey-800': '#1D2939',
  'grey-900': '#101828',
  // PURPLE
  'purple-25': '#FCFAFF',
  'purple-50': '#F9F5FF',
  'purple-100': '#F4EBFF',
  'purple-200': '#E9D7FE',
  'purple-300': '#D6BBFB',
  'purple-400': '#B692F6',
  'purple-500': '#9E77ED',
  'purple-600': '#7F56D9',
  'purple-700': '#6941C6',
  'purple-800': '#53389E',
  'purple-900': '#42307D',
  // ERROR
  'error-25': '#FFFBFA',
  'error-50': '#FEF3F2',
  'error-100': '#FEE4E2',
  'error-200': '#FECDCA',
  'error-300': '#FDA29B',
  'error-400': '#DD6A6A',
  'error-500': '#F04438',
  'error-600': '#D92D20',
  'error-700': '#B42318',
  'error-800': '#912018',
  'error-900': '#7A271A',
  // WARNING
  'warning-25': '#FFFCF5',
  'warning-50': '#FFFAEB',
  'warning-100': '#FDFEC7',
  'warning-200': '#F4FA67',
  'warning-300': '#FEC84B',
  'warning-400': '#FDB022',
  'warning-500': '#F79009',
  'warning-600': '#DC6803',
  'warning-700': '#B0601B',
  'warning-800': '#93370D',
  'warning-900': '#7A2E0E',
  // SUCCESS
  'green-25': '#F6FEF9',
  'green-50': '#ECFDF3',
  'green-100': '#D1FADF',
  'green-200': '#A6F4C5',
  'green-300': '#6CE9A6',
  'green-400': '#32D583',
  'green-500': '#12B76A',
  'green-600': '#039855',
  'green-700': '#0F8470',
  'green-800': '#3C6B61',
  'green-900': '#054F31',
  // BLUE GREY
  'blueGrey-25': '#FCFCFD',
  'blueGrey-50': '#F8F9FC',
  'blueGrey-100': '#EAECF5',
  'blueGrey-200': '#D5D9EB',
  'blueGrey-300': '#AFB5D9',
  'blueGrey-400': '#717BBC',
  'blueGrey-500': '#4E5BA6',
  'blueGrey-600': '#3E4784',
  'blueGrey-700': '#363F72',
  'blueGrey-800': '#293056',
  'blueGrey-900': '#101323',
  // BLUE
  'blue-25': '#F5FAFF',
  'blue-50': '#EFF8FF',
  'blue-100': '#D1E9FF',
  'blue-200': '#B2DDFF',
  'blue-300': '#84CAFF',
  'blue-400': '#53B1FD',
  'blue-500': '#2E90FA',
  'blue-600': '#1570EF',
  'blue-700': '#0500FF',
  'blue-800': '#1849A9',
  'blue-900': '#0D3F94',
  // INDIGO
  'indigo-25': '#F5F8FF',
  'indigo-50': '#EEF4FF',
  'indigo-100': '#E0EAFF',
  'indigo-200': '#C7D7FE',
  'indigo-300': '#A4BCFD',
  'indigo-400': '#8098F9',
  'indigo-500': '#6172F3',
  'indigo-600': '#444CE7',
  'indigo-700': '#3538CD',
  'indigo-800': '#2D31A6',
  'indigo-900': '#2D3282',
  // PINK
  'pink-25': '#FEF6FB',
  'pink-50': '#FDF2FA',
  'pink-100': '#FCE7F6',
  'pink-200': '#FCCEEE',
  'pink-300': '#FAA7E0',
  'pink-400': '#F670C7',
  'pink-500': '#EE46BC',
  'pink-600': '#DD2590',
  'pink-700': '#C11574',
  'pink-800': '#9E165F',
  'pink-900': '#851651',
  // ROSE
  'rose-25': '#FFF5F6',
  'rose-50': '#FFF1F3',
  'rose-100': '#FFE4E8',
  'rose-200': '#FECDD6',
  'rose-300': '#FEA3B4',
  'rose-400': '#FD6F8E',
  'rose-500': '#F63D68',
  'rose-600': '#E31B54',
  'rose-700': '#C01048',
  'rose-800': '#A11043',
  'rose-900': '#89123E',
  // ORANGE
  'orange-25': '#FFFAF5',
  'orange-50': '#FFF6ED',
  'orange-100': '#FFEAD5',
  'orange-200': '#FDDCAB',
  'orange-300': '#FEB273',
  'orange-400': '#FD853A',
  'orange-500': '#FB6514',
  'orange-600': '#EC4A0A',
  'orange-700': '#C4320A',
  'orange-800': '#9C2A10',
  'orange-900': '#7E2410',
  // YELLOW
  'yellow-25': '#FEC84B40',
};

export default OrbyColorPalette;
