import { makeAutoObservable, observable } from 'mobx';
import AuthStore from './AuthStore';
import FeatureFlagStore from './FeatureFlagStore';
import OrbyInternalAppStore from './OrbyInternalAppStore';
import UserStore from './UserStore';
import WorkflowsStore from './WorkflowsStore';
import AnnouncementStore from './AnnouncementStore';

export class RootStore {
  @observable authStore: AuthStore;
  @observable userStore: UserStore;
  @observable workflowsStore: WorkflowsStore;
  @observable internalAppStore: OrbyInternalAppStore;
  @observable featureFlagStore: FeatureFlagStore;
  @observable announcementStore: AnnouncementStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.userStore = new UserStore(this);
    this.workflowsStore = new WorkflowsStore(this);
    this.internalAppStore = new OrbyInternalAppStore(this);
    this.featureFlagStore = new FeatureFlagStore(this);
    this.announcementStore = new AnnouncementStore(this);
    makeAutoObservable(this);
  }
}
