import { Chip, styled } from '@mui/material';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { ACCENT_COLOR_1 } from '../../utils/constants';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  return {
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(1, 2),
  };
}) as typeof Chip;

export const CurrentBreadcrumb: React.FC<{
  label: string;
  icon?: ReactElement;
}> = ({ icon, label }) => {
  return (
    <StyledBreadcrumb
      icon={icon}
      label={label}
      sx={{
        backgroundColor: '#031026',
        color: 'white',
      }}
    />
  );
};

export const NonCurrentBreadcrumb: React.FC<{
  label: string;
  href: string;
  icon?: ReactElement;
}> = ({ icon, label, href }) => {
  return (
    <StyledBreadcrumb
      label={label}
      icon={icon}
      sx={{
        backgroundColor: ACCENT_COLOR_1,
        color: 'white',
        cursor: 'pointer',
      }}
      component={Link}
      to={href}
    />
  );
};
