import { Box } from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../../../components/core/CustomTypography';
import React from 'react';

const DefaultsStep: React.FC = () => {
  return (
    <Box
      width={'70%'}
      marginBottom={'15px'}
      marginLeft={'14px'}
      marginTop={'21px'}
    >
      <CustomTypography typographyType={TypographyType.Header4}>
        Few Shot Config
      </CustomTypography>

      <ul>
        <li>
          <CustomTypography>Temperature = 0.0</CustomTypography>
        </li>
        <li>
          <CustomTypography>Top P = 0.8</CustomTypography>
        </li>
        <li>
          <CustomTypography>Top K = 40</CustomTypography>
        </li>
        <li>
          <CustomTypography>Candidate Count = 1</CustomTypography>
        </li>
        <li>
          <CustomTypography>
            Pipeline Type = &quot;HYBRID_V3&quot;
          </CustomTypography>
        </li>
        <li>
          <CustomTypography>
            Remove Entities Without BBox = &quot;true&quot;
          </CustomTypography>
        </li>
        <li>
          <CustomTypography>Email Data Model</CustomTypography>
          <ul>
            <li>
              <CustomTypography>
                Extraction Spec Model Name = &quot;gpt-3.5-turbo-0125&quot;
              </CustomTypography>
            </li>
          </ul>
        </li>
      </ul>

      <CustomTypography typographyType={TypographyType.Header4}>
        Document Classification Config
      </CustomTypography>

      <ul>
        <li>
          <CustomTypography>
            Model Name = &quot;textembedding-gecko@001&quot;
          </CustomTypography>
        </li>
        <li>
          <CustomTypography>Model Family = &quot;palm&quot;</CustomTypography>
        </li>
        <li>
          <CustomTypography>
            Pipeline Type = &quot;DEFAULT&quot;
          </CustomTypography>
        </li>
        <li>
          <CustomTypography>
            If All Default L1 Labels Same as Classification Labels
          </CustomTypography>
          <ul>
            <li>
              <CustomTypography>
                Label to Description Map = &quot;Default L1 Label to Description
                Map&quot;
              </CustomTypography>
            </li>
            <li>
              <CustomTypography>
                Model Name = &quot;gpt-4o&quot;
              </CustomTypography>
            </li>
            <li>
              <CustomTypography>
                Model Family = &quot;gpt&quot;
              </CustomTypography>
            </li>
            <li>
              <CustomTypography>
                Pipeline Type = &quot;ZEROSHOT_LLM&quot;
              </CustomTypography>
            </li>
          </ul>
        </li>
        <li>
          <CustomTypography>
            If All Default L2 Labels Same as Classification Labels
          </CustomTypography>
          <ul>
            <li>
              <CustomTypography>
                Label to Description Map = &quot;Default L2 Label to Description
                Map&quot;
              </CustomTypography>
            </li>
            <li>
              <CustomTypography>
                Model Name = &quot;gpt-4o&quot;
              </CustomTypography>
            </li>
            <li>
              <CustomTypography>
                Model Family = &quot;gpt&quot;
              </CustomTypography>
            </li>
            <li>
              <CustomTypography>
                Pipeline Type = &quot;ZEROSHOT_LLM&quot;
              </CustomTypography>
            </li>
          </ul>
        </li>
      </ul>

      <CustomTypography typographyType={TypographyType.Header4}>
        Fetch Similar Documents Config
      </CustomTypography>

      <ul>
        <li>
          <CustomTypography>
            Fetch Count = &quot;Max Number of Examples in Workflow Config&quot;
          </CustomTypography>
        </li>
      </ul>
    </Box>
  );
};

export default DefaultsStep;
