import {
  Box,
  Button,
  CircularProgress,
  Step,
  StepContent,
  StepIconProps,
  StepLabel,
  Stepper,
  Tooltip,
} from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../../components/core/CustomTypography';
import { useStore } from '../../../hooks/useStore';
import { observer } from 'mobx-react';
import {
  DocumentClassificationTunableConfig,
  FetchSimilarDocumentsTunableConfig,
  FewShotTunableConfig,
  Hyperparameter,
} from 'protos/common/hyperparameter';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toastService } from '../../../services/ToastService';
import {
  PERMISSION_MANAGE_USERS,
  STEP_CONTENT_WIDTH,
} from '../../../utils/constants';
import DefaultsStep from './components/DefaultsStep';
import DescriptionStep from './components/DescriptionStep';
import DocumentClassificationStep from './components/DocumentClassificationStep';
import FetchSimilarDocumentsStep from './components/FetchSimilarDocumentsStep';
import FewShotStep from './components/FewShotStep';
import HyperparameterDetailStep from './components/HyperparameterDetailStep';
import { hyperparameterDetailSteps } from './utils/hyperparameterDetailSteps';

const HyperparameterDetail: React.FC = observer(() => {
  const { hyperparameterId } = useParams();
  const navigate = useNavigate();
  const store = useStore();
  const {
    createHyperparameters,
    getHyperparameterDetails,
    hyperparameterDetailLoading,
    hyperparameterDetail,
    hyperparameterDetailError,
    resetHyperparameterDetail,
    updateHyperparameter,
  } = store.internalAppStore;
  const { user } = store.authStore;
  const hasEditPermission =
    user?.permittedActions?.includes(PERMISSION_MANAGE_USERS) ?? false;
  const [activeStep, setActiveStep] = useState(0);
  const [hyperparameter, setHyperparameter] = useState<
    Hyperparameter | undefined
  >();
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    if (hyperparameterId !== 'create') {
      getHyperparameterDetails(`hyperparameters/${hyperparameterId}`);
    } else {
      resetHyperparameterDetail();
    }
  }, [hyperparameterId]);

  useEffect(() => {
    if (hyperparameterDetailError) {
      toastService.showError(
        `Failed to fetch hyperparameter details: ${hyperparameterDetailError}`,
      );
      resetHyperparameterDetail();
      navigate(-1);
    }
  }, [hyperparameterDetailError, navigate]);

  useEffect(() => {
    if (hyperparameterDetail) {
      setHyperparameter({ ...hyperparameterDetail });
      setDisplayName(hyperparameterDetail.displayName ?? '');
      setFewShotTunableConfig(hyperparameterDetail?.fewShotTunableConfig ?? {});
      setDocumentClassificationTunableConfig(
        hyperparameterDetail?.documentClassificationTunableConfig ?? {},
      );
      setFetchSimilarDocumentsTunableConfig(
        hyperparameterDetail?.fetchSimilarDocumentsTunableConfig ?? {},
      );
    } else {
      setHyperparameter(undefined);
      setActiveStep(0);
      setDisplayName('');
      setFewShotTunableConfig({});
      setDocumentClassificationTunableConfig({});
      setFetchSimilarDocumentsTunableConfig({});
    }
  }, [hyperparameterDetail]);

  const [displayName, setDisplayName] = useState<string>(
    hyperparameter?.displayName ?? '',
  );
  const [fewShotTunableConfig, setFewShotTunableConfig] =
    useState<FewShotTunableConfig>(hyperparameter?.fewShotTunableConfig ?? {});
  const [
    documentClassificationTunableConfig,
    setDocumentClassificationTunableConfig,
  ] = useState<DocumentClassificationTunableConfig>(
    hyperparameter?.documentClassificationTunableConfig ?? {},
  );
  const [
    fetchSimilarDocumentsTunableConfig,
    setFetchSimilarDocumentsTunableConfig,
  ] = useState<FetchSimilarDocumentsTunableConfig>(
    hyperparameter?.fetchSimilarDocumentsTunableConfig ?? {},
  );
  const isNewHyperparameter = hyperparameter === undefined;

  const handleFewShotUpdate = (
    newFewShotTunableConfig: FewShotTunableConfig,
  ) => {
    setFewShotTunableConfig(newFewShotTunableConfig);
    setIsEdited(true);
  };

  const handleDocumentClassificationUpdate = (
    newDocumentClassificationTunableConfig: DocumentClassificationTunableConfig,
  ) => {
    setDocumentClassificationTunableConfig(
      newDocumentClassificationTunableConfig,
    );
    setIsEdited(true);
  };

  const handleFetchSimilarDocumentsUpdate = (
    newFetchSimilarDocumentsTunableConfig: FetchSimilarDocumentsTunableConfig,
  ) => {
    setFetchSimilarDocumentsTunableConfig(
      newFetchSimilarDocumentsTunableConfig,
    );
    setIsEdited(true);
  };

  const handleCreateHyperparameter = () => {
    if (displayName.length === 0) {
      toastService.showError('Display name cannot be empty');
      return;
    }

    const newHyperparameter: Hyperparameter = {
      displayName,
    };

    if (fewShotTunableConfig) {
      newHyperparameter.fewShotTunableConfig = fewShotTunableConfig;

      const { nucleusSamplingParameters } = fewShotTunableConfig;

      if (nucleusSamplingParameters) {
        if (
          nucleusSamplingParameters.topP !== undefined &&
          parseFloat(nucleusSamplingParameters.topP) === 0
        ) {
          toastService.showError('Top P cannot be 0');
          return;
        }

        if (
          nucleusSamplingParameters.topK !== undefined &&
          nucleusSamplingParameters.topK === 0
        ) {
          toastService.showError('Top K cannot be 0');
          return;
        }

        if (
          nucleusSamplingParameters.candidateCount !== undefined &&
          nucleusSamplingParameters.candidateCount === 0
        ) {
          toastService.showError('Candidate Count cannot be 0');
          return;
        }
      }
    }

    if (documentClassificationTunableConfig) {
      newHyperparameter.documentClassificationTunableConfig =
        documentClassificationTunableConfig;
    }

    if (fetchSimilarDocumentsTunableConfig) {
      newHyperparameter.fetchSimilarDocumentsTunableConfig =
        fetchSimilarDocumentsTunableConfig;
    }

    createHyperparameters({
      hyperparameter: newHyperparameter,
    }).then((err?: Error) => {
      if (err) {
        toastService.showError(`Failed to create hyperparameter: ${err}`);
        return;
      }
      setActiveStep(0);
      setDisplayName('');
      setFewShotTunableConfig({});
      setDocumentClassificationTunableConfig({});
      setFetchSimilarDocumentsTunableConfig({});
      toastService.showSuccess('Hyperparameter created successfully');
      goBack();
    });
  };

  const handleUpdateHyperparameter = () => {
    const newHyperparameter: Hyperparameter = {
      name: hyperparameter?.name,
      displayName,
    };

    if (fewShotTunableConfig) {
      newHyperparameter.fewShotTunableConfig = fewShotTunableConfig;

      const { nucleusSamplingParameters } = fewShotTunableConfig;

      if (nucleusSamplingParameters) {
        if (
          nucleusSamplingParameters.topP !== undefined &&
          parseFloat(nucleusSamplingParameters.topP) === 0
        ) {
          toastService.showError('Top P cannot be 0');
          return;
        }

        if (
          nucleusSamplingParameters.topK !== undefined &&
          nucleusSamplingParameters.topK === 0
        ) {
          toastService.showError('Top K cannot be 0');
          return;
        }

        if (
          nucleusSamplingParameters.candidateCount !== undefined &&
          nucleusSamplingParameters.candidateCount === 0
        ) {
          toastService.showError('Candidate Count cannot be 0');
          return;
        }
      }
    }

    if (documentClassificationTunableConfig) {
      newHyperparameter.documentClassificationTunableConfig =
        documentClassificationTunableConfig;
    }

    if (fetchSimilarDocumentsTunableConfig) {
      newHyperparameter.fetchSimilarDocumentsTunableConfig =
        fetchSimilarDocumentsTunableConfig;
    }

    updateHyperparameter(newHyperparameter).then((err?: Error) => {
      if (err) {
        toastService.showError(`Failed to update hyperparameter: ${err}`);
        return;
      }
      setActiveStep(0);
      setDisplayName('');
      setFewShotTunableConfig({});
      setDocumentClassificationTunableConfig({});
      setFetchSimilarDocumentsTunableConfig({});
      toastService.showSuccess('Hyperparameter updated successfully');
      goBack();
    });
  };

  const goBack = () => {
    navigate(-1);
  };

  const getStepContent = (index: number) => {
    switch (index) {
      case 0:
        return (
          <DescriptionStep
            isViewOnly={!isNewHyperparameter}
            value={displayName}
            setValue={setDisplayName}
          />
        );
      case 1:
        return <DefaultsStep />;
      case 2:
        return (
          <FewShotStep
            isViewOnly={!isNewHyperparameter && !hasEditPermission}
            value={fewShotTunableConfig}
            setValue={handleFewShotUpdate}
          />
        );
      case 3:
        return (
          <DocumentClassificationStep
            isViewOnly={!isNewHyperparameter && !hasEditPermission}
            value={documentClassificationTunableConfig}
            setValue={handleDocumentClassificationUpdate}
          />
        );
      case 4:
        return (
          <FetchSimilarDocumentsStep
            isViewOnly={!isNewHyperparameter && !hasEditPermission}
            value={fetchSimilarDocumentsTunableConfig}
            setValue={handleFetchSimilarDocumentsUpdate}
          />
        );
      default:
        return <></>;
    }
  };

  if (hyperparameterDetailLoading) {
    return (
      <Box display={'flex'} pt={'60px'} justifyContent={'center'}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box bgcolor={'#F6F8FC'} paddingX={'50px'} paddingTop={'50px'}>
      <CustomTypography
        component={'h1'}
        typographyType={TypographyType.Header2}
        sx={{ marginBottom: '10px', marginTop: '20px' }}
      >
        {isNewHyperparameter ? 'Create Hyperparameter' : displayName}
      </CustomTypography>
      <Stepper
        sx={{
          overflow: 'none',
          marginBottom: '80px',
          '& .MuiStepConnector-root .MuiStepConnector-line': {
            borderColor: 'transparent',
          },
        }}
        orientation='vertical'
        activeStep={activeStep}
      >
        {hyperparameterDetailSteps.map((step, index) => {
          return (
            <Step
              key={step.title}
              sx={{
                '& .MuiStepLabel-root': {
                  padding: '0px 0px',
                },
              }}
              active={activeStep === index}
              onClick={() => setActiveStep(index)}
            >
              <StepLabel
                StepIconComponent={(props: StepIconProps) => (
                  <HyperparameterDetailStep
                    key={`index-${step.title}`}
                    {...props}
                    {...step}
                    sx={{
                      cursor: 'pointer',
                    }}
                    ariaLabel={`Create Hyperparameter Step ${index + 1} ${
                      step.title
                    }`}
                  />
                )}
              ></StepLabel>
              <StepContent
                sx={{
                  borderLeft: 'none !important',
                  paddingLeft: '0px !important',
                  marginLeft: '0px  !important',
                  width: `${STEP_CONTENT_WIDTH}px`,
                }}
                TransitionProps={{ unmountOnExit: false }}
              >
                <Box
                  display={'flex'}
                  flexWrap={'wrap'}
                  bgcolor={'#FFFFFF'}
                  position={'relative'}
                >
                  {getStepContent(index)}
                </Box>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
      <Box
        paddingBottom={'20px'}
        sx={{
          display: 'flex',
          justifyContent: 'end',
          gap: '25px',
        }}
      >
        <Button onClick={() => goBack()} variant='outlined'>
          Cancel
        </Button>

        {isNewHyperparameter && (
          <Button
            variant='contained'
            onClick={handleCreateHyperparameter}
            disabled={!isEdited}
          >
            Submit
          </Button>
        )}
        {!isNewHyperparameter && (
          <Tooltip title={!hasEditPermission ? 'Insufficient Permissions' : ''}>
            <Button
              variant='contained'
              disabled={!isEdited || !hasEditPermission}
              onClick={handleUpdateHyperparameter}
            >
              Update
            </Button>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
});

export default HyperparameterDetail;
