import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  ListItemText,
  TextField,
} from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../../components/core/CustomTypography';
import { useStore } from '../../../hooks/useStore';
import { observer } from 'mobx-react';
import { ListOrganizationsRequest } from 'protos/pb/orby_internal/orby_internal_service';
import { Organization } from 'protos/pb/v1alpha1/organization';
import React, { useEffect, useState } from 'react';

const OrganizationSelector: React.FC<{
  selectedValue: Organization;
  setSelectedValue: React.Dispatch<React.SetStateAction<Organization>>;
}> = observer(({ selectedValue, setSelectedValue }) => {
  const store = useStore();
  const {
    loadingOrganizationsList,
    organizationsList,
    totalOrganizationsCount,
    getOrganizationsList,
  } = store.internalAppStore;
  const [page, setPage] = useState(1);
  const [option, setOption] = useState<Organization>(selectedValue);
  const [inputValue, setInputValue] = useState('');
  const hasMoreItems =
    (totalOrganizationsCount ?? 0) > organizationsList.length;

  const fetchNextOrganizations = async (nextPage: number) => {
    const req: ListOrganizationsRequest = {};
    req.pageNumber = nextPage;
    req.pageSize = 5;
    getOrganizationsList(req, false /* Refresh */);
  };

  useEffect(() => {
    setPage(1);
    const req: ListOrganizationsRequest = {};
    req.pageNumber = 1;
    req.pageSize = 5;
    getOrganizationsList(req, true /* Refresh */);
  }, []);

  const handleLoadMore = () => {
    if (loadingOrganizationsList || !hasMoreItems) {
      return;
    }
    const nextPage = page + 1;
    setPage(nextPage);
    fetchNextOrganizations(nextPage);
  };

  const handleInputChange = (event: any, newValue: string) => {
    setInputValue(newValue);
  };

  return (
    <>
      <CustomTypography
        typographyType={TypographyType.Label}
        sx={{ margin: '5px 0' }}
      >
        Select Organization
      </CustomTypography>
      <Autocomplete
        value={option}
        onChange={(event, newValue) => {
          setSelectedValue(newValue ?? {});
          setOption(newValue ?? {});
        }}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        options={organizationsList}
        getOptionLabel={(option) => option.displayName ?? ''}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            placeholder='Type to search organizations'
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox checked={selected} style={{ marginRight: 8 }} />
            <ListItemText primary={option.displayName} />
          </li>
        )}
        ListboxProps={{
          style: {
            maxHeight: 250,
            overflow: 'auto',
          },
          onScroll: (event: any) => {
            const bottomReached =
              event.target.scrollHeight - event.target.scrollTop ===
              event.target.clientHeight;
            if (bottomReached && !loadingOrganizationsList) {
              handleLoadMore();
            }
          },
        }}
        loading={loadingOrganizationsList}
        loadingText={<CircularProgress size={24} />}
        sx={{ margin: '16px 0' }}
      />
    </>
  );
});

export default OrganizationSelector;
