import { Box, Container, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useStore } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { storageService } from '../../services/StorageService';
import { GOOGLE_DEFAULT_SCOPE, IS_LOCAL } from '../../utils/constants';
import { ReactComponent as GoogleLogo } from '../../static/icons/google.svg';

export const LoginForm: React.FC = observer(() => {
  const store = useStore();
  const {
    token,
    loginErrorMessage,
    googleLogin,
    setLoginErrorMessage,
    loginWithEmailPassword,
  } = store.authStore;
  const googleScope = GOOGLE_DEFAULT_SCOPE;

  // CLEAR ANY STORED INFO BEFORE PROCEEDING FOR NEW LOGIN
  const preLoginClearStorage = (): Promise<boolean> => {
    return new Promise((resolve) => {
      storageService.deleteStoredValues().then(() => {
        resolve(true);
      });
    });
  };

  const googleSignIn = () => {
    preLoginClearStorage().then(() => {
      // Setting the error message to undefined
      // once user clicks the login button again
      setLoginErrorMessage();
      googleLogin({ scope: googleScope });
    });
  };

  const loginWithPassword = () => {
    preLoginClearStorage().then(() => {
      // Setting the error message to undefined
      // once user clicks the login button again
      setLoginErrorMessage();
      loginWithEmailPassword();
    });
  };

  const transformUrl = (path: string | null) => {
    if (path === '/workflow/create') {
      return '/workflow';
    }
    return path;
  };

  useEffect(() => {
    if (token !== undefined) {
      const url: URL = new URL(window.location.href);
      const params: URLSearchParams = url.searchParams;
      const redirectUrl: string | null = transformUrl(
        params.get('redirectUrl'),
      );
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        window.location.href = '/';
      }
    }
  }, [token]);

  return (
    <Container
      sx={{
        display: 'flex',
        flex: 1,
        paddingLeft: '80px!important',
        paddingRight: '80px!important',
      }}
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          flexDirection: 'column',
        }}
      >
        <Typography
          align='center'
          fontSize={'30px'}
          fontWeight='600'
          lineHeight={1}
          color={'#3E1C96'}
          sx={{ marginBottom: '60px' }}
        >
          Sign in to Orby AI Internal App
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '0px',
          }}
        >
          <Button
            onClick={() => googleSignIn()}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                googleSignIn();
              }
            }}
            variant='outlined'
            sx={{
              width: '360px',
              borderRadius: '30px',
              flexGrow: 1,
              background: '#FFFFFF',
              color: '#fff',
              padding: '10px, 12px, 10px, 12px',
              height: '50px',
              border: '1px solid rgba(234, 236, 240, 1)',
              boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
              '&:hover': {
                border: 'unset',
              },
              '&:focus': {
                background: 'rgba(22, 105, 247, 0.04)',
              },
            }}
          >
            <GoogleLogo width={20} />
            <Typography
              style={{
                marginLeft: '10px',
              }}
              color={'#344054'}
              fontSize={'14px'}
              fontWeight='600'
              lineHeight={'20px'}
            >
              Sign in with Google
            </Typography>
          </Button>
        </Box>

        {IS_LOCAL && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '0px',
            }}
          >
            <Button
              onClick={() => loginWithPassword()}
              variant='outlined'
              sx={{
                width: '360px',
                borderRadius: '30px',
                flexGrow: 1,
                background: '#FFFFFF',
                color: '#fff',
                padding: '10px, 12px, 10px, 12px',
                height: '50px',
                border: '1px solid rgba(234, 236, 240, 1)',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                '&:hover': {
                  border: 'unset',
                },
                '&:focus': {
                  background: 'rgba(22, 105, 247, 0.04)',
                },
              }}
            >
              <Typography
                style={{
                  marginLeft: '10px',
                }}
                color={'#344054'}
                fontSize={'14px'}
                fontWeight='600'
                lineHeight={'20px'}
              >
                Login with Email and Password
              </Typography>
            </Button>
          </Box>
        )}

        {loginErrorMessage && (
          <Typography
            aria-live='assertive'
            sx={{
              paddingTop: '10px',
            }}
            color={'#FF0000'}
            align='center'
          >
            {loginErrorMessage}
          </Typography>
        )}

        <Box
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          marginTop={'60px'}
        >
          <Link
            style={{
              color: '#3E1C96',
              fontSize: '14px',
              fontWeight: '600',
              marginRight: '15px',
            }}
            id={'privacy-policy-link'}
            className='focused-link'
            to='https://orby.ai/privacy-policy'
            target='_blank'
            rel='noreferrer'
          >
            Privacy policy{' '}
          </Link>
          <Link
            style={{
              color: '#3E1C96',
              fontSize: '14px',
              // lineHeight: 2.5,
              fontWeight: 600,
              marginRight: '15px',
            }}
            className='focused-link'
            id={'terms-of-service-link'}
            to='https://orby.ai/terms-of-service'
            target='_blank'
            rel='noreferrer'
          >
            Terms of use
          </Link>
        </Box>
      </Box>
    </Container>
  );
});
