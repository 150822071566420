import { Box, ThemeProvider } from '@mui/material';
import { StoreProvider } from './hooks/useStore';
import OptionsApp from './pages/Options/Options';
import React from 'react';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RootStore } from './store/store';
import './index.css';
import reportWebVitals from './reportWebVitals';
import getTheme from './theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const rootStore = new RootStore();
// recommended approach to create routes
const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <Box sx={{ height: '100vh', width: '100vw' }}>
        <OptionsApp />
      </Box>
    ),
  },
]);
root.render(
  <HelmetProvider>
    <ToastContainer
      hideProgressBar
      style={{ width: 'fit-content', maxWidth: '400px', minWidth: '320px' }}
      pauseOnFocusLoss={false}
      toastStyle={{ backgroundColor: '#f2ffeb', border: '1px solid #72be47' }}
    />
    <StoreProvider store={rootStore}>
      <ThemeProvider theme={getTheme()}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </StoreProvider>
  </HelmetProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
