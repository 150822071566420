import { makeAutoObservable, observable, runInAction } from 'mobx';
import {
  ListWorkflowsRequest,
  Workflow,
} from 'protos/pb/v1alpha2/workflows_service';
import { workflowService } from '../services/WorkflowService';
import { RootStore } from './store';

class WorkflowsStore {
  rootStore: RootStore;
  @observable loadingWorkflowTemplates = false;
  @observable listWorkflowTemplatesError?: any;
  @observable workflowTemplates: Workflow[] = [];
  @observable workflowTemplatesNextPageToken?: string;
  @observable workflowTemplateTotalSize?: number;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  listWorkflowTemplates = async (
    req: ListWorkflowsRequest,
    refresh: boolean,
  ) => {
    runInAction(() => {
      this.loadingWorkflowTemplates = true;
    });
    try {
      const { response, error } = await workflowService.listWorkflows(req);
      if (error) {
        runInAction(() => {
          this.listWorkflowTemplatesError = error;
        });
      } else {
        runInAction(() => {
          if (refresh) {
            this.workflowTemplates = response?.workflows ?? [];
          } else {
            this.workflowTemplates.push(...(response?.workflows ?? []));
          }
          this.workflowTemplatesNextPageToken = response?.nextPageToken;
          this.workflowTemplateTotalSize = response?.totalSize;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.listWorkflowTemplatesError = error;
      });
    } finally {
      runInAction(() => {
        this.loadingWorkflowTemplates = false;
      });
    }
  };

  resetTemplatesError = () => {
    runInAction(() => {
      this.listWorkflowTemplatesError = undefined;
    });
  };
}

export default WorkflowsStore;
