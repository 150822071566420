import moment from 'moment';
import { UserOrgRole } from 'protos/pb/v1alpha1/user';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import driveSvg from '../static/icons/drive-logo.svg';
import gmailSvg from '../static/icons/gmail-logo.svg';
import outlookSvg from '../static/icons/outlook.svg';
import salesForceSvg from '../static/icons/sales-force-logo.svg';
import sapSvg from '../static/icons/sap-logo.svg';
import sftpSvg from '../static/icons/sftp-logo.svg';
import sheetsSvg from '../static/icons/sheets-logo.svg';
import excelSvg from '../static/icons/spreadsheet.svg';
import { ApplicationName } from './enums';

export function isAdmin(role?: UserOrgRole) {
  return role === UserOrgRole.ROLE_ADMIN;
}

export const getApplicationNames = (workflow: Workflow): string[] => {
  const arr: string[] = [];
  workflow.steps?.map((step) => {
    step.triggers?.map((trigger) => {
      if (
        trigger.application &&
        !arr.find((v) => v === trigger.application) &&
        Object.entries(ApplicationName).find(
          ([, value]) => value === trigger.application,
        )
      ) {
        arr.push(trigger.application);
      }
    });
    step.actions?.map((action) => {
      if (
        action.application &&
        !arr.find((v) => v === action.application) &&
        Object.entries(ApplicationName).find(
          ([, value]) => value === action.application,
        )
      ) {
        arr.push(action.application);
      }
    });
  });
  return arr;
};

export const getLogoByName = (name: string) => {
  switch (name) {
    case ApplicationName.GoogleDrive:
      return driveSvg;
    case ApplicationName.Outlook:
      return outlookSvg;
    case ApplicationName.Gmail:
      return gmailSvg;
    case ApplicationName.MSExcel:
      return excelSvg;
    case ApplicationName.GoogleSheets:
      return sheetsSvg;
    case ApplicationName.SalesForce:
      return salesForceSvg;
    case ApplicationName.Sap:
      return sapSvg;
    case ApplicationName.SftpServer:
      return sftpSvg;
  }
};

export const formatDate = (ts: Date, format = 'MM/DD/YYYY HH:mm:ss') => {
  return moment(ts).format(format);
};
