import {
  ArrowForwardIos,
  KeyboardArrowDown,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  OutputOutlined,
} from '@mui/icons-material';
import {
  Box,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import CustomTypography, { TypographyType } from '../core/CustomTypography';
import { useStore } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import { LogoutRequest } from 'protos/pb/v1alpha1/users_service';
import React, { FC, memo } from 'react';
import { storageService } from '../../services/StorageService';
import { ELLIPSIS_STYLE } from '../../utils/constants';

interface DrawerFooterProps {
  collapse: boolean;
  setCollapse: (x: boolean) => void;
}

const DrawerFooter: FC<DrawerFooterProps> = observer(
  ({ collapse, setCollapse }) => {
    const store = useStore();
    const { loggedInUser, selectedOrgInfo } = store.userStore;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const iconRight = collapse ? 20 : 13;

    const handleOpenLogout = () => {
      setAnchorEl(document.getElementById('drawer-footer'));
    };

    const handleCloseLogout = () => {
      setAnchorEl(null);
    };

    const handleLogout = async () => {
      const token = await storageService.getStoredToken();
      if (token) {
        const req: LogoutRequest = {};
        req.name = token.sessionId;
        store.authStore.logoutUser(req);
      }
    };

    return (
      <Box
        bgcolor={'#354051'}
        width={collapse ? '80px' : '280px'}
        height={'80px'}
        display={'flex'}
        position={'relative'}
        id='drawer-footer'
      >
        <IconButton
          onClick={() => setCollapse(!collapse)}
          aria-label={collapse ? 'Open Drawer' : 'Close Drawer'}
          sx={{
            position: 'absolute',
            top: -60,
            right: 10,
            '&:focus': { outline: '2px solid red', borderRadius: '5px' },
          }}
        >
          {collapse ? (
            <KeyboardDoubleArrowRight
              sx={{ color: '#B6B6B6', fontSize: '30px' }}
            />
          ) : (
            <KeyboardDoubleArrowLeft
              sx={{ color: '#B6B6B6', fontSize: '30px' }}
            />
          )}
        </IconButton>
        {!collapse && (
          <>
            <Box title={loggedInUser?.email}>
              <CustomTypography
                weight={600}
                color={'#FFFFFF'}
                sx={{
                  ...ELLIPSIS_STYLE,
                  maxWidth: '200px',
                  position: 'absolute',
                  top: 20,
                  left: 17,
                }}
              >
                {loggedInUser?.email}
              </CustomTypography>
            </Box>
            <Box
              sx={{ position: 'absolute', top: 39, left: 17, right: 17 }}
              display={'flex'}
            >
              <CustomTypography color={'#B6B6B6'}>
                {selectedOrgInfo?.orgDisplayName}
              </CustomTypography>
              <IconButton
                onClick={handleOpenLogout}
                aria-label='User Menu'
                sx={{
                  width: '25px',
                  height: '19px',
                  '&:focus': {
                    outline: '2px solid red',
                    borderRadius: '5px',
                    padding: '6px',
                  },
                }}
              >
                <KeyboardArrowDown
                  sx={{ color: '#B6B6B6', width: '25px', height: '19px' }}
                />
              </IconButton>
            </Box>
          </>
        )}
        {collapse && (
          <IconButton
            onClick={handleOpenLogout}
            aria-label='User Menu'
            sx={{
              position: 'absolute',
              top: '28px',
              right: iconRight,
              width: '25px',
              height: '19px',
              '&:focus': {
                outline: '2px solid red',
                borderRadius: '5px',
                padding: '6px',
              },
            }}
          >
            <ArrowForwardIos
              sx={{ color: '#B6B6B6', width: '25px', height: '19px' }}
            />
          </IconButton>
        )}
        <Menu
          id='fade-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseLogout}
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          slotProps={{
            paper: {
              sx: {
                '&.MuiMenu-paper': {
                  left: '0px !important',
                  width: '240px !important',
                },
              },
            },
          }}
        >
          <MenuItem sx={{ marginTop: '5px' }} onClick={handleLogout}>
            <ListItemIcon>
              <OutputOutlined fontSize='small' sx={{ marginX: '10px' }} />
            </ListItemIcon>
            <ListItemText>
              <CustomTypography
                typographyType={TypographyType.Header4}
                color='#000000'
              >
                Log out
              </CustomTypography>
            </ListItemText>
          </MenuItem>
        </Menu>
      </Box>
    );
  },
);

export default memo(DrawerFooter);
