import { SxProps, TableCell, Theme } from '@mui/material';
import React, { FC, memo } from 'react';

interface CustomTableCellProps {
  children?: any;
  sx?: SxProps<Theme>;
  title?: string;
  ellipsis?: boolean;
}

const CustomTableCell: FC<CustomTableCellProps> = ({
  children,
  sx,
  title,
  ellipsis = true,
}) => {
  return (
    <TableCell
      title={title}
      sx={{
        ...sx,
        whiteSpace: ellipsis ? 'nowrap' : 'normal',
        overflow: ellipsis ? 'hidden' : 'normal',
        textOverflow: ellipsis ? 'ellipsis' : 'normal',
        maxWidth: '100%',
      }}
    >
      {children}
    </TableCell>
  );
};

export default memo(CustomTableCell);
