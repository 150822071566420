export const getFontWeight = (weight: string) => {
  switch (weight) {
    case 'regular':
      return 400;
    case 'medium':
      return 500;
    case 'semibold':
      return 600;
    case 'bold':
      return 700;
    default:
      return 400; // Default to 'regular' if no weight is specified
  }
};

export const getFontSize = (size: string) => {
  switch (size) {
    case 'xl':
      return '1.25rem';
    case 'lg':
      return '1.125rem';
    case 'md':
      return '1rem';
    case 'sm':
      return '.875rem';
    case 'xs':
      return '.75rem';
    case 'display-sm':
      return '1.875rem';
    case 'display-xs':
      return '1.5rem';
    case 'display-md':
      return '2.25rem';
    default:
      return '.875rem'; // Default to 'sm' if no size is specified
  }
};

export const getLineHeight = (size: string) => {
  switch (size) {
    case 'xl':
      return '1.875rem';
    case 'lg':
      return '1.75rem';
    case 'md':
      return '1.5rem';
    case 'sm':
      return '1.25rem';
    case 'xs':
      return '1.125rem';
    case 'display-sm':
      return '2.375rem';
    case 'display-xs':
      return '2rem';
    case 'display-md':
      return '2.75rem';
    default:
      return '1.25rem'; // Default to 'sm' if no size is specified
  }
};
