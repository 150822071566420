import { MoreVert } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';
import CustomPagination from '../../../components/core/CustomPagination';
import CustomTableCell from '../../../components/core/CustomTableCell';
import CustomTableLabel from '../../../components/core/CustomTableLabel';
import SlideUpTransition from '../../../components/core/SlideUpTransition';
import StyledTableRow from '../../../components/core/StyledTableRow';
import { useStore } from '../../../hooks/useStore';
import { observer } from 'mobx-react';
import {
  ListUsersRequest,
  Role,
  UserWithPrivilageLevel,
} from 'protos/pb/orby_internal/orby_internal_service';
import React, { useEffect, useState } from 'react';
import { toastService } from '../../../services/ToastService';
import { ACCENT_COLOR_1 } from '../../../utils/constants';
import UserPermissionsDisplay from './UserPermissionsDisplay';
import UserRoleAssignment from './UserRoleAssignment';

const UsersList: React.FC = observer(() => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const store = useStore();
  const {
    loadingUsers,
    users,
    usersError,
    totalUsersCount,
    getUsersList,
    resetUsersError,
  } = store.internalAppStore;

  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const pagedData = users?.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  const [emailPrefix, setEmailPrefix] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedUser, setSelectedUser] = useState<UserWithPrivilageLevel>({});
  const [selectedUserForAssignment, setSelectedUserForAssignment] =
    useState<UserWithPrivilageLevel>();
  const [selectedUserForDetails, setSelectedUserForDetails] =
    useState<UserWithPrivilageLevel>();
  const open = Boolean(anchorEl);

  useEffect(() => {
    refreshPage();
  }, []);

  useEffect(() => {
    refreshPage();
  }, [emailPrefix]);

  const refreshPage = () => {
    setPage(1);
    const req: ListUsersRequest = {
      pageNumber: page,
      pageSize: rowsPerPage,
    };
    let filterStr = '';
    if (emailPrefix) {
      filterStr = `email_prefix=${emailPrefix}`;
    }
    req.filter = filterStr;
    getUsersList(req, true);
  };

  useEffect(() => {
    if (usersError) {
      toastService.showError(`Failed to fetch users: ${usersError}`);
      resetUsersError();
    }
  }, [usersError]);

  const fetchNextUsers = async (nextPage: number) => {
    getUsersList(
      { pageNumber: nextPage, pageSize: rowsPerPage },
      false /* Refresh */,
    );
  };

  const getPrivilageLevel = (user: UserWithPrivilageLevel) => {
    if (user.role && user.role !== Role.UNRECOGNIZED) {
      switch (user.role) {
        case Role.INTERNAL_ADMIN:
          return 'Admin';
        case Role.INTERNAL_BILLING_MANAGER:
          return 'Billing Manager';
        case Role.INTERNAL_REVIEWER:
          return 'Reviewer';
        case Role.INTERNAL_USER:
          return 'User';
      }
    }
    return '-';
  };

  return (
    <>
      {selectedUserForAssignment && (
        <Dialog
          fullScreen={fullScreen}
          open={!!selectedUserForAssignment}
          onClose={() => {
            setSelectedUserForAssignment(undefined);
          }}
          TransitionComponent={SlideUpTransition}
        >
          <UserRoleAssignment
            user={selectedUserForAssignment}
            onClose={() => {
              setSelectedUserForAssignment(undefined);
            }}
          />
        </Dialog>
      )}

      {selectedUserForDetails && (
        <Dialog
          fullScreen={fullScreen}
          open={!!selectedUserForDetails}
          onClose={() => {
            setSelectedUserForDetails(undefined);
          }}
          TransitionComponent={SlideUpTransition}
        >
          <UserPermissionsDisplay
            user={selectedUserForDetails}
            onClose={() => {
              setSelectedUserForDetails(undefined);
            }}
          />
        </Dialog>
      )}

      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'flex-start'}
        width={'100%'}
        gap={'8px'}
        marginTop={'24px'}
        marginBottom={'24px'}
      >
        <Box>
          <TextField
            variant='outlined'
            value={emailPrefix}
            size={'small'}
            type={'email'}
            InputProps={{
              startAdornment: <GridSearchIcon fontSize='medium' />,
              sx: {
                '& .MuiOutlinedInput-input  ': {
                  WebkitTextFillColor: '#000000 !important',
                  height: '23px',
                  lineHeight: '16px',
                },
              },
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setEmailPrefix(event.target.value)
            }
          />
        </Box>
      </Box>

      {loadingUsers ? (
        <Box display={'flex'} pt={'60px'} justifyContent={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            marginTop: '30px',
            borderRadius: '10px',
          }}
        >
          <TableContainer
            sx={{
              borderStartStartRadius: 'inherit',
              borderStartEndRadius: 'inherit',
            }}
          >
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: ACCENT_COLOR_1,
                  }}
                >
                  <CustomTableCell title='Email'>
                    <CustomTableLabel label='Email' />
                  </CustomTableCell>
                  <CustomTableCell title='Current Privilage Level'>
                    <CustomTableLabel label='Current Privilage Level' />
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pagedData.map((t, index) => (
                  <StyledTableRow
                    key={t.email}
                    tabIndex={0}
                    role='row'
                    sx={{
                      backgroundColor: index % 2 === 0 ? 'white' : '#f9f9f9',
                      cursor: 'pointer',
                      ':hover': { backgroundColor: '#1669F74D' },
                    }}
                  >
                    <CustomTableCell title={t.email ?? ''}>
                      {t.email ?? ''}
                    </CustomTableCell>
                    <CustomTableCell
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <span title={getPrivilageLevel(t)}>
                        {getPrivilageLevel(t)}
                      </span>
                      <span>
                        <IconButton
                          aria-controls={open ? 'long-menu' : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-haspopup='true'
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.stopPropagation();
                            setAnchorEl(event.currentTarget);
                            setSelectedUser(t);
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      </span>
                    </CustomTableCell>
                  </StyledTableRow>
                ))}
                <Menu
                  autoFocus={false}
                  disableAutoFocusItem={true}
                  aria-hidden={false}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => {
                    setAnchorEl(null);
                  }}
                  slotProps={{
                    paper: {
                      style: {
                        boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.15)',
                        borderRadius: '4px',
                      },
                    },
                  }}
                >
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedUserForAssignment(selectedUser);
                    }}
                  >
                    Assign Role
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedUserForDetails(selectedUser);
                    }}
                  >
                    View Permissions
                  </MenuItem>
                </Menu>
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            rowsPerPage={10}
            totalSize={totalUsersCount}
            page={page - 1}
            onNextPage={(newPage: number) => {
              setPage(newPage + 1);
              if (
                users.length < totalUsersCount! &&
                users.length < (newPage + 1) * rowsPerPage
              ) {
                fetchNextUsers(newPage + 1);
              }
            }}
            onPrevPage={(newPage: number) => {
              setPage(newPage + 1);
            }}
          />
        </Box>
      )}
    </>
  );
});

export default UsersList;
