import { Box, Chip, InputBase, Paper } from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../../../components/core/CustomTypography';
import React, { useEffect, useRef, useState } from 'react';
import { toastService } from '../../../../services/ToastService';

const EmailsAdditionComponent: React.FC<{
  addedEmails: string[];
  setAddedEmails: React.Dispatch<React.SetStateAction<string[]>>;
  label: string;
}> = (props) => {
  const { addedEmails, setAddedEmails, label } = props;
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      addEmail();
    } else if (event.key === 'Backspace' && !inputValue) {
      removeLastEmail();
    }
  };

  const handleChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const addEmail = () => {
    if (inputValue.trim() && isValidEmail(inputValue)) {
      if (!addedEmails.includes(inputValue.trim())) {
        setAddedEmails((prev) => [...prev, inputValue.trim()]);
        setInputValue('');
      } else {
        toastService.showError('Email already added');
      }
    } else {
      toastService.showError('Enter a valid email');
    }
  };

  const isValidEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailPattern.test(email);
  };

  const removeEmail = (index: number) => {
    setAddedEmails((prev) => prev.filter((_, i) => i !== index));
  };

  const removeLastEmail = () => {
    setAddedEmails((prev) => prev.slice(0, -1));
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [addedEmails]);

  return (
    <Box sx={{ margin: '16px 0' }}>
      <CustomTypography
        typographyType={TypographyType.Label}
        sx={{ margin: '5px 0' }}
      >
        {label}
      </CustomTypography>
      <Paper
        component='form'
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          padding: '8px',
          gap: '4px',
          border: '1px solid #ccc',
          borderRadius: '4px',
        }}
        elevation={0}
      >
        {addedEmails.map((email, index) => (
          <Chip
            key={email}
            label={email}
            onDelete={() => removeEmail(index)}
            sx={{ margin: '2px' }}
          />
        ))}
        <InputBase
          inputRef={inputRef}
          value={inputValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder='Type an email and press Enter or Space'
          sx={{ flex: 1, minWidth: '120px' }}
        />
      </Paper>
    </Box>
  );
};

export default EmailsAdditionComponent;
