import { Box } from '@mui/material';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import orbyFullLogo from '../../static/orby-white.svg';
import orbyCompactLogo from '../../static/o-white.svg';
import { DRAWER_TABS } from './tabs';

import DrawerFooter from './DrawerFooter';
import DrawerTab from './DrawerTab';
import { observer } from 'mobx-react';
import {
  PERMISSION_MANAGE_USERS,
  ROUTE_NAME_USERS,
} from '../../utils/constants';
import { useStore } from '../../hooks/useStore';

interface Props {
  collapse: boolean;
  setCollapse: (val: boolean) => void;
}

const WebDrawerWrapper: FC<Props> = observer(({ collapse, setCollapse }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const store = useStore();
  const selectedPath = location.pathname;

  const { user } = store.authStore;
  const isUserAdmin =
    user?.permittedActions?.includes(PERMISSION_MANAGE_USERS) ?? false;

  const routesToHideForNonAdmin = [`/${ROUTE_NAME_USERS}`];

  const handleDrawerItemClick = useCallback(
    (path: string) => navigate(path),
    [],
  );

  const checkPermission = (route: string) => {
    return isUserAdmin || !routesToHideForNonAdmin.includes(route);
  };

  const renderHeader = useMemo(() => {
    return (
      <Box
        component={'img'}
        height={'30px'}
        sx={{
          marginTop: '40px',
          marginBottom: '37px',
        }}
        src={collapse ? orbyCompactLogo : orbyFullLogo}
        alt='Orby Logo'
      />
    );
  }, [collapse]);

  return (
    <Box
      bgcolor={'#031026'}
      width={collapse ? '80px' : '280px'}
      height='100vh'
      position={'fixed'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      zIndex={40}
    >
      <>
        {renderHeader}
        <Box
          display={'flex'}
          role={'tablist'}
          flexDirection={'column'}
          alignItems={'center'}
          height={'calc(100% - 189px)'}
        >
          {DRAWER_TABS.map((tab, index) => {
            if (checkPermission(tab.route)) {
              return (
                <DrawerTab
                  key={`drawer_tab_${tab.title}`}
                  data={tab}
                  index={index}
                  collapse={collapse}
                  isSelected={selectedPath.includes(tab.route)}
                  onClick={handleDrawerItemClick}
                />
              );
            }
          })}
        </Box>
      </>
      <DrawerFooter collapse={collapse} setCollapse={setCollapse} />
    </Box>
  );
});

export default memo(WebDrawerWrapper);
