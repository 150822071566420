import { makeAutoObservable, observable, runInAction } from 'mobx';
import {
  Announcement,
  ListAnnouncementsRequest,
  GetAnnouncementRequest,
  CreateAnnouncementRequest,
  UpdateAnnouncementRequest,
  DeleteAnnouncementRequest,
  UploadAnnouncementImageRequest,
} from 'protos/pb/orby_internal/orby_internal_service';
import { RootStore } from './store';
import { announcementService } from '../services/AnnouncementService';

class AnnouncementStore {
  rootStore: RootStore;
  @observable announcements: Announcement[] = [];
  @observable totalSize?: number = 0;
  @observable loadingAnnouncements = false;
  @observable listAnnouncementsError?: Error;

  @observable createdAnnouncement?: Announcement;
  @observable updatedAnnouncement?: Announcement;

  @observable processingAnnouncement = false;
  @observable processAnnouncementError?: Error;

  @observable selectedAnnouncement?: Announcement;
  @observable loadingAnnouncement = false;
  @observable loadAnnouncementError?: Error;

  @observable announcementImages: Map<string, string> = new Map();
  @observable uploadAnnouncementImageError?: Error;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  listAnnouncements = async (
    req: ListAnnouncementsRequest,
    refresh = false,
  ) => {
    runInAction(() => {
      this.loadingAnnouncements = true;
      this.listAnnouncementsError = undefined;
    });
    try {
      const { response, error } =
        await announcementService.getAnnouncements(req);
      runInAction(() => {
        if (response) {
          if (refresh) {
            this.announcements = response.announcements ?? [];
          } else {
            this.announcements.push(...(response.announcements ?? []));
          }
          this.totalSize = response.totalSize;
        } else {
          this.listAnnouncementsError = error;
        }
        this.loadingAnnouncements = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingAnnouncements = false;
        this.listAnnouncementsError = error as Error;
      });
    }
  };

  createAnnouncement = async (announcement: Announcement) => {
    this.processingAnnouncement = true;
    try {
      const { response, error } = await announcementService.createAnnouncement(
        CreateAnnouncementRequest.create({ announcement }),
      );
      runInAction(() => {
        if (response) {
          this.createdAnnouncement = response.announcement;
        } else {
          this.processAnnouncementError = error;
        }
        this.processingAnnouncement = false;
      });
    } catch (error) {
      runInAction(() => {
        this.processingAnnouncement = false;
        this.processAnnouncementError = error as Error;
      });
    }
  };

  updateAnnouncement = async (announcement: Announcement) => {
    this.processingAnnouncement = true;
    try {
      const { response, error } = await announcementService.updateAnnouncement(
        UpdateAnnouncementRequest.create({
          announcement: announcement,
          fieldMask: [
            'display_name',
            'description',
            'type',
            'header',
            'content_blocks',
          ],
        }),
      );
      runInAction(() => {
        if (response) {
          this.updatedAnnouncement = response.announcement;
        } else {
          this.processAnnouncementError = error;
        }
        this.processingAnnouncement = false;
      });
    } catch (error) {
      runInAction(() => {
        this.processingAnnouncement = false;
        this.processAnnouncementError = error as Error;
      });
    }
  };

  publishAnnouncement = async (announcement: Announcement, active: boolean) => {
    this.processingAnnouncement = true;
    try {
      announcement.isActive = active;
      const { response, error } = await announcementService.updateAnnouncement(
        UpdateAnnouncementRequest.create({
          announcement: announcement,
          fieldMask: ['is_active'],
        }),
      );
      runInAction(() => {
        if (response) {
          this.updatedAnnouncement = response.announcement;
          this.announcements = this.announcements.map((ff) =>
            ff.id === announcement.id && response.announcement
              ? response.announcement
              : ff,
          );
        } else {
          this.processAnnouncementError = error;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.processAnnouncementError = error as Error;
      });
    } finally {
      this.processingAnnouncement = false;
    }
  };

  deleteAnnouncement = async (id: string) => {
    this.processingAnnouncement = true;
    try {
      await announcementService.deleteAnnouncement(
        DeleteAnnouncementRequest.create({ id }),
      );
      runInAction(() => {
        this.announcements = this.announcements.filter((ff) => ff.id !== id);
        this.processingAnnouncement = false;
      });
    } catch (error) {
      runInAction(() => {
        this.processingAnnouncement = false;
        this.processAnnouncementError = error as Error;
      });
    }
  };

  getAnnouncement = async (id: string, isPreview: boolean) => {
    this.loadingAnnouncement = true;
    try {
      const { response, error } = await announcementService.getAnnouncement(
        GetAnnouncementRequest.create({ id, isPreview }),
      );
      runInAction(() => {
        if (response) {
          this.selectedAnnouncement = response.announcement;
          if (response.images) {
            this.announcementImages = response.images.reduce((map, image) => {
              if (image.url && image.signedUrl) {
                map.set(image.url, image.signedUrl);
              }
              return map;
            }, new Map<string, string>());
          }
        } else {
          this.loadAnnouncementError = error;
        }
        this.loadingAnnouncement = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingAnnouncement = false;
        this.loadAnnouncementError = error as Error;
      });
    }
  };

  uploadAnnouncementImage = async (
    id: string | undefined,
    content: Uint8Array,
  ) => {
    try {
      const { response, error } =
        await announcementService.uploadAnnouncementImage(
          UploadAnnouncementImageRequest.create({
            id: id,
            imageContent: content,
          }),
        );
      runInAction(() => {
        if (response?.image?.url && response?.image?.signedUrl) {
          this.announcementImages.set(
            response.image.url,
            response.image.signedUrl,
          );
        } else {
          this.uploadAnnouncementImageError = error;
        }
      });
      return response?.image?.url;
    } catch (error) {
      runInAction(() => {
        this.uploadAnnouncementImageError = error as Error;
      });
    }
  };

  setSelectedAnnouncement = (announcement?: Announcement) => {
    runInAction(() => {
      this.selectedAnnouncement = announcement;
    });
  };

  resetAnnouncementDetail = () => {
    runInAction(() => {
      this.selectedAnnouncement = undefined;
      this.loadAnnouncementError = undefined;
      this.loadingAnnouncement = false;
    });
  };

  clearErrors = () => {
    runInAction(() => {
      this.listAnnouncementsError = undefined;
      this.loadAnnouncementError = undefined;
      this.processAnnouncementError = undefined;
    });
  };
}

export default AnnouncementStore;
