import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  ListItemText,
  TextField,
} from '@mui/material';
import CustomTypography, {
  TypographyType,
} from '../../../components/core/CustomTypography';
import { useStore } from '../../../hooks/useStore';
import { observer } from 'mobx-react';
import { ListWorkflowsForOrganizationRequest } from 'protos/pb/orby_internal/orby_internal_service';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import React, { useEffect, useState } from 'react';

const WorkflowSelector: React.FC<{
  selectedValue: Workflow;
  setSelectedValue: React.Dispatch<React.SetStateAction<Workflow>>;
  selectedOrganization: string;
  isDisabled: boolean;
}> = observer(
  ({ selectedValue, setSelectedValue, selectedOrganization, isDisabled }) => {
    const store = useStore();
    const {
      loadingWorkflowsForOrganization,
      workflowsForOrganization,
      totalWorkflowsForOrganizationCount,
      getWorkflowsForOrganization,
    } = store.internalAppStore;
    const [page, setPage] = useState(1);
    const [option, setOption] = useState<Workflow>(selectedValue);
    const [inputValue, setInputValue] = useState('');
    const hasMoreItems =
      (totalWorkflowsForOrganizationCount ?? 0) >
      workflowsForOrganization.length;

    const fetchNextWorkflows = async (nextPage: number) => {
      const req: ListWorkflowsForOrganizationRequest = {
        organizationName: selectedOrganization,
      };
      req.pageNumber = nextPage;
      req.pageSize = 5;
      getWorkflowsForOrganization(req, false /* Refresh */);
    };

    useEffect(() => {
      setPage(1);
      const req: ListWorkflowsForOrganizationRequest = {
        organizationName: selectedOrganization,
      };
      req.pageNumber = 1;
      req.pageSize = 5;
      getWorkflowsForOrganization(req, true /* Refresh */);
    }, []);

    const handleLoadMore = () => {
      if (loadingWorkflowsForOrganization || !hasMoreItems) {
        return;
      }
      const nextPage = page + 1;
      setPage(nextPage);
      fetchNextWorkflows(nextPage);
    };

    const handleInputChange = (event: any, newValue: string) => {
      setInputValue(newValue);
    };

    return (
      <>
        <CustomTypography
          typographyType={TypographyType.Label}
          sx={{ margin: '5px 0' }}
        >
          Select Workflow
        </CustomTypography>
        <Autocomplete
          disabled={isDisabled}
          value={option}
          onChange={(event, newValue) => {
            setSelectedValue(newValue ?? {});
            setOption(newValue ?? {});
          }}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          options={workflowsForOrganization}
          getOptionLabel={(option) => option.displayName ?? ''}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              placeholder='Type to search workflows'
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox checked={selected} style={{ marginRight: 8 }} />
              <ListItemText primary={option.displayName} />
            </li>
          )}
          ListboxProps={{
            style: {
              maxHeight: 250,
              overflow: 'auto',
            },
            onScroll: (event: any) => {
              const bottomReached =
                event.target.scrollHeight - event.target.scrollTop ===
                event.target.clientHeight;
              if (bottomReached && !loadingWorkflowsForOrganization) {
                handleLoadMore();
              }
            },
          }}
          loading={loadingWorkflowsForOrganization}
          loadingText={<CircularProgress size={24} />}
          sx={{ margin: '16px 0' }}
        />
      </>
    );
  },
);

export default WorkflowSelector;
