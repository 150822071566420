/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { DeletedObjectInfo } from "../../common/common";
import { UserProfileInfo } from "../../common/user_profile";
import { Timestamp } from "../../google/protobuf/timestamp";

export const protobufPackage = "pb.v1alpha2";

export enum Operator {
  UNSPECIFIED = 0,
  EQUAL = 2,
  LESS_THAN = 3,
  GREATER_THAN = 4,
  CONTAINS = 5,
  EXISTS = 6,
  DOES_NOT_EXIST = 7,
  GREATER_THAN_EQUAL = 8,
  LESS_THAN_EQUAL = 9,
  UNRECOGNIZED = -1,
}

export function operatorFromJSON(object: any): Operator {
  switch (object) {
    case 0:
    case "OPERATOR_UNSPECIFIED":
      return Operator.UNSPECIFIED;
    case 2:
    case "OPERATOR_EQUAL":
      return Operator.EQUAL;
    case 3:
    case "OPERATOR_LESS_THAN":
      return Operator.LESS_THAN;
    case 4:
    case "OPERATOR_GREATER_THAN":
      return Operator.GREATER_THAN;
    case 5:
    case "OPERATOR_CONTAINS":
      return Operator.CONTAINS;
    case 6:
    case "OPERATOR_EXISTS":
      return Operator.EXISTS;
    case 7:
    case "OPERATOR_DOES_NOT_EXIST":
      return Operator.DOES_NOT_EXIST;
    case 8:
    case "OPERATOR_GREATER_THAN_EQUAL":
      return Operator.GREATER_THAN_EQUAL;
    case 9:
    case "OPERATOR_LESS_THAN_EQUAL":
      return Operator.LESS_THAN_EQUAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Operator.UNRECOGNIZED;
  }
}

export function operatorToJSON(object: Operator): string {
  switch (object) {
    case Operator.UNSPECIFIED:
      return "OPERATOR_UNSPECIFIED";
    case Operator.EQUAL:
      return "OPERATOR_EQUAL";
    case Operator.LESS_THAN:
      return "OPERATOR_LESS_THAN";
    case Operator.GREATER_THAN:
      return "OPERATOR_GREATER_THAN";
    case Operator.CONTAINS:
      return "OPERATOR_CONTAINS";
    case Operator.EXISTS:
      return "OPERATOR_EXISTS";
    case Operator.DOES_NOT_EXIST:
      return "OPERATOR_DOES_NOT_EXIST";
    case Operator.GREATER_THAN_EQUAL:
      return "OPERATOR_GREATER_THAN_EQUAL";
    case Operator.LESS_THAN_EQUAL:
      return "OPERATOR_LESS_THAN_EQUAL";
    case Operator.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum LogicalOperator {
  UNSPECIFIED = 0,
  AND = 1,
  OR = 2,
  NOT = 3,
  UNRECOGNIZED = -1,
}

export function logicalOperatorFromJSON(object: any): LogicalOperator {
  switch (object) {
    case 0:
    case "LOGICAL_OPERATOR_UNSPECIFIED":
      return LogicalOperator.UNSPECIFIED;
    case 1:
    case "LOGICAL_OPERATOR_AND":
      return LogicalOperator.AND;
    case 2:
    case "LOGICAL_OPERATOR_OR":
      return LogicalOperator.OR;
    case 3:
    case "LOGICAL_OPERATOR_NOT":
      return LogicalOperator.NOT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LogicalOperator.UNRECOGNIZED;
  }
}

export function logicalOperatorToJSON(object: LogicalOperator): string {
  switch (object) {
    case LogicalOperator.UNSPECIFIED:
      return "LOGICAL_OPERATOR_UNSPECIFIED";
    case LogicalOperator.AND:
      return "LOGICAL_OPERATOR_AND";
    case LogicalOperator.OR:
      return "LOGICAL_OPERATOR_OR";
    case LogicalOperator.NOT:
      return "LOGICAL_OPERATOR_NOT";
    case LogicalOperator.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ConditionType {
  /** UNSPECIFIED - Default value if none is specified. */
  UNSPECIFIED = 0,
  ANY_EXTRACTED_FIELD = 1,
  AVERAGE_CONFIDENCE_SCORE = 2,
  SPECIFIC_EXTRACTED_FIELD = 3,
  ANY_EMPTY_PREDICTIONS = 4,
  RANDOM_SAMPLE_PERCENT = 5,
  UNRECOGNIZED = -1,
}

export function conditionTypeFromJSON(object: any): ConditionType {
  switch (object) {
    case 0:
    case "CONDITION_TYPE_UNSPECIFIED":
      return ConditionType.UNSPECIFIED;
    case 1:
    case "CONDITION_TYPE_ANY_EXTRACTED_FIELD":
      return ConditionType.ANY_EXTRACTED_FIELD;
    case 2:
    case "CONDITION_TYPE_AVERAGE_CONFIDENCE_SCORE":
      return ConditionType.AVERAGE_CONFIDENCE_SCORE;
    case 3:
    case "CONDITION_TYPE_SPECIFIC_EXTRACTED_FIELD":
      return ConditionType.SPECIFIC_EXTRACTED_FIELD;
    case 4:
    case "CONDITION_TYPE_ANY_EMPTY_PREDICTIONS":
      return ConditionType.ANY_EMPTY_PREDICTIONS;
    case 5:
    case "CONDITION_TYPE_RANDOM_SAMPLE_PERCENT":
      return ConditionType.RANDOM_SAMPLE_PERCENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ConditionType.UNRECOGNIZED;
  }
}

export function conditionTypeToJSON(object: ConditionType): string {
  switch (object) {
    case ConditionType.UNSPECIFIED:
      return "CONDITION_TYPE_UNSPECIFIED";
    case ConditionType.ANY_EXTRACTED_FIELD:
      return "CONDITION_TYPE_ANY_EXTRACTED_FIELD";
    case ConditionType.AVERAGE_CONFIDENCE_SCORE:
      return "CONDITION_TYPE_AVERAGE_CONFIDENCE_SCORE";
    case ConditionType.SPECIFIC_EXTRACTED_FIELD:
      return "CONDITION_TYPE_SPECIFIC_EXTRACTED_FIELD";
    case ConditionType.ANY_EMPTY_PREDICTIONS:
      return "CONDITION_TYPE_ANY_EMPTY_PREDICTIONS";
    case ConditionType.RANDOM_SAMPLE_PERCENT:
      return "CONDITION_TYPE_RANDOM_SAMPLE_PERCENT";
    case ConditionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Connector {
  /** Resource name. Format: connectors/{ID} */
  name?: string | undefined;
  displayName?: string | undefined;
  description?: string | undefined;
  sourceWorkflow?: WorkflowInfo | undefined;
  destinationWorkflow?: WorkflowInfo | undefined;
  groupCondition?:
    | CompositeGroupCondition
    | undefined;
  /** Organization resource name. Format: organizations/{ID} */
  orgResourceName?: string | undefined;
  assignmentConfig?:
    | AssignmentConfig
    | undefined;
  /**
   * This field allows soft deletion over time by
   * marking workflow for deletion without immediately deleting it.
   */
  deletedObjectInfo?:
    | DeletedObjectInfo
    | undefined;
  /** Do not use, use creator instead. */
  creatorEmail?: string | undefined;
  createTime?: Date | undefined;
  lastModifiedTime?:
    | Date
    | undefined;
  /** Creator info of the connector */
  creator?: UserProfileInfo | undefined;
}

/**
 * Used to store the configurations to do preferential assignments
 * across source and destination tasks
 */
export interface AssignmentConfig {
  /**
   * Bool indicating preference for same user in source workflow and destination workflow
   * Currently only source R1 user is being considered
   */
  preserveAssignee?: boolean | undefined;
}

export interface WorkflowInfo {
  /** Wokflow resource name. Format: workflows/{ID} */
  workflowResourceName?: string | undefined;
  workflowName?: string | undefined;
}

export interface Condition {
  /** We should no longer use attribute, it will be deprecated soon, use attribute_type instead */
  attribute?: string | undefined;
  operator?: Operator | undefined;
  value?: string | undefined;
  attributeType?: AttributeType | undefined;
}

export interface AttributeType {
  /** For simple entity, parent will be empty */
  parent?: string | undefined;
  name?: string | undefined;
}

export interface CompositeGroupCondition {
  logicalOperator?: LogicalOperator | undefined;
  conditions?: Condition[] | undefined;
  nestedConditions?: CompositeGroupCondition[] | undefined;
  groupIndex?: number | undefined;
}

export interface ConditionOptions {
  /** group_condition will contain all the specific attribute conditions i.e xyz > 20 */
  groupCondition?:
    | CompositeGroupCondition
    | undefined;
  /** percent_of_random_sample moved here also as we are using condition types */
  percentOfRandomSample?:
    | number
    | undefined;
  /** confidence_score is for avg and any extracted field condition types */
  confidenceScore?: number | undefined;
}

function createBaseConnector(): Connector {
  return {
    name: "",
    displayName: "",
    description: "",
    sourceWorkflow: undefined,
    destinationWorkflow: undefined,
    groupCondition: undefined,
    orgResourceName: "",
    assignmentConfig: undefined,
    deletedObjectInfo: undefined,
    creatorEmail: "",
    createTime: undefined,
    lastModifiedTime: undefined,
    creator: undefined,
  };
}

export const Connector = {
  encode(message: Connector, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined && message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.displayName !== undefined && message.displayName !== "") {
      writer.uint32(18).string(message.displayName);
    }
    if (message.description !== undefined && message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.sourceWorkflow !== undefined) {
      WorkflowInfo.encode(message.sourceWorkflow, writer.uint32(34).fork()).ldelim();
    }
    if (message.destinationWorkflow !== undefined) {
      WorkflowInfo.encode(message.destinationWorkflow, writer.uint32(42).fork()).ldelim();
    }
    if (message.groupCondition !== undefined) {
      CompositeGroupCondition.encode(message.groupCondition, writer.uint32(50).fork()).ldelim();
    }
    if (message.orgResourceName !== undefined && message.orgResourceName !== "") {
      writer.uint32(58).string(message.orgResourceName);
    }
    if (message.assignmentConfig !== undefined) {
      AssignmentConfig.encode(message.assignmentConfig, writer.uint32(66).fork()).ldelim();
    }
    if (message.deletedObjectInfo !== undefined) {
      DeletedObjectInfo.encode(message.deletedObjectInfo, writer.uint32(74).fork()).ldelim();
    }
    if (message.creatorEmail !== undefined && message.creatorEmail !== "") {
      writer.uint32(82).string(message.creatorEmail);
    }
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(90).fork()).ldelim();
    }
    if (message.lastModifiedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastModifiedTime), writer.uint32(98).fork()).ldelim();
    }
    if (message.creator !== undefined) {
      UserProfileInfo.encode(message.creator, writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Connector {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConnector();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.sourceWorkflow = WorkflowInfo.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.destinationWorkflow = WorkflowInfo.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.groupCondition = CompositeGroupCondition.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.orgResourceName = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.assignmentConfig = AssignmentConfig.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.deletedObjectInfo = DeletedObjectInfo.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.creatorEmail = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.lastModifiedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.creator = UserProfileInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Connector {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      displayName: isSet(object.displayName) ? globalThis.String(object.displayName) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      sourceWorkflow: isSet(object.sourceWorkflow) ? WorkflowInfo.fromJSON(object.sourceWorkflow) : undefined,
      destinationWorkflow: isSet(object.destinationWorkflow)
        ? WorkflowInfo.fromJSON(object.destinationWorkflow)
        : undefined,
      groupCondition: isSet(object.groupCondition)
        ? CompositeGroupCondition.fromJSON(object.groupCondition)
        : undefined,
      orgResourceName: isSet(object.orgResourceName) ? globalThis.String(object.orgResourceName) : "",
      assignmentConfig: isSet(object.assignmentConfig) ? AssignmentConfig.fromJSON(object.assignmentConfig) : undefined,
      deletedObjectInfo: isSet(object.deletedObjectInfo)
        ? DeletedObjectInfo.fromJSON(object.deletedObjectInfo)
        : undefined,
      creatorEmail: isSet(object.creatorEmail) ? globalThis.String(object.creatorEmail) : "",
      createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
      lastModifiedTime: isSet(object.lastModifiedTime) ? fromJsonTimestamp(object.lastModifiedTime) : undefined,
      creator: isSet(object.creator) ? UserProfileInfo.fromJSON(object.creator) : undefined,
    };
  },

  toJSON(message: Connector): unknown {
    const obj: any = {};
    if (message.name !== undefined && message.name !== "") {
      obj.name = message.name;
    }
    if (message.displayName !== undefined && message.displayName !== "") {
      obj.displayName = message.displayName;
    }
    if (message.description !== undefined && message.description !== "") {
      obj.description = message.description;
    }
    if (message.sourceWorkflow !== undefined) {
      obj.sourceWorkflow = WorkflowInfo.toJSON(message.sourceWorkflow);
    }
    if (message.destinationWorkflow !== undefined) {
      obj.destinationWorkflow = WorkflowInfo.toJSON(message.destinationWorkflow);
    }
    if (message.groupCondition !== undefined) {
      obj.groupCondition = CompositeGroupCondition.toJSON(message.groupCondition);
    }
    if (message.orgResourceName !== undefined && message.orgResourceName !== "") {
      obj.orgResourceName = message.orgResourceName;
    }
    if (message.assignmentConfig !== undefined) {
      obj.assignmentConfig = AssignmentConfig.toJSON(message.assignmentConfig);
    }
    if (message.deletedObjectInfo !== undefined) {
      obj.deletedObjectInfo = DeletedObjectInfo.toJSON(message.deletedObjectInfo);
    }
    if (message.creatorEmail !== undefined && message.creatorEmail !== "") {
      obj.creatorEmail = message.creatorEmail;
    }
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    if (message.lastModifiedTime !== undefined) {
      obj.lastModifiedTime = message.lastModifiedTime.toISOString();
    }
    if (message.creator !== undefined) {
      obj.creator = UserProfileInfo.toJSON(message.creator);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Connector>, I>>(base?: I): Connector {
    return Connector.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Connector>, I>>(object: I): Connector {
    const message = createBaseConnector();
    message.name = object.name ?? "";
    message.displayName = object.displayName ?? "";
    message.description = object.description ?? "";
    message.sourceWorkflow = (object.sourceWorkflow !== undefined && object.sourceWorkflow !== null)
      ? WorkflowInfo.fromPartial(object.sourceWorkflow)
      : undefined;
    message.destinationWorkflow = (object.destinationWorkflow !== undefined && object.destinationWorkflow !== null)
      ? WorkflowInfo.fromPartial(object.destinationWorkflow)
      : undefined;
    message.groupCondition = (object.groupCondition !== undefined && object.groupCondition !== null)
      ? CompositeGroupCondition.fromPartial(object.groupCondition)
      : undefined;
    message.orgResourceName = object.orgResourceName ?? "";
    message.assignmentConfig = (object.assignmentConfig !== undefined && object.assignmentConfig !== null)
      ? AssignmentConfig.fromPartial(object.assignmentConfig)
      : undefined;
    message.deletedObjectInfo = (object.deletedObjectInfo !== undefined && object.deletedObjectInfo !== null)
      ? DeletedObjectInfo.fromPartial(object.deletedObjectInfo)
      : undefined;
    message.creatorEmail = object.creatorEmail ?? "";
    message.createTime = object.createTime ?? undefined;
    message.lastModifiedTime = object.lastModifiedTime ?? undefined;
    message.creator = (object.creator !== undefined && object.creator !== null)
      ? UserProfileInfo.fromPartial(object.creator)
      : undefined;
    return message;
  },
};

function createBaseAssignmentConfig(): AssignmentConfig {
  return { preserveAssignee: false };
}

export const AssignmentConfig = {
  encode(message: AssignmentConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.preserveAssignee !== undefined && message.preserveAssignee !== false) {
      writer.uint32(8).bool(message.preserveAssignee);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssignmentConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssignmentConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.preserveAssignee = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssignmentConfig {
    return { preserveAssignee: isSet(object.preserveAssignee) ? globalThis.Boolean(object.preserveAssignee) : false };
  },

  toJSON(message: AssignmentConfig): unknown {
    const obj: any = {};
    if (message.preserveAssignee !== undefined && message.preserveAssignee !== false) {
      obj.preserveAssignee = message.preserveAssignee;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssignmentConfig>, I>>(base?: I): AssignmentConfig {
    return AssignmentConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssignmentConfig>, I>>(object: I): AssignmentConfig {
    const message = createBaseAssignmentConfig();
    message.preserveAssignee = object.preserveAssignee ?? false;
    return message;
  },
};

function createBaseWorkflowInfo(): WorkflowInfo {
  return { workflowResourceName: "", workflowName: "" };
}

export const WorkflowInfo = {
  encode(message: WorkflowInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.workflowResourceName !== undefined && message.workflowResourceName !== "") {
      writer.uint32(10).string(message.workflowResourceName);
    }
    if (message.workflowName !== undefined && message.workflowName !== "") {
      writer.uint32(18).string(message.workflowName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WorkflowInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorkflowInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.workflowResourceName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.workflowName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WorkflowInfo {
    return {
      workflowResourceName: isSet(object.workflowResourceName) ? globalThis.String(object.workflowResourceName) : "",
      workflowName: isSet(object.workflowName) ? globalThis.String(object.workflowName) : "",
    };
  },

  toJSON(message: WorkflowInfo): unknown {
    const obj: any = {};
    if (message.workflowResourceName !== undefined && message.workflowResourceName !== "") {
      obj.workflowResourceName = message.workflowResourceName;
    }
    if (message.workflowName !== undefined && message.workflowName !== "") {
      obj.workflowName = message.workflowName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WorkflowInfo>, I>>(base?: I): WorkflowInfo {
    return WorkflowInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WorkflowInfo>, I>>(object: I): WorkflowInfo {
    const message = createBaseWorkflowInfo();
    message.workflowResourceName = object.workflowResourceName ?? "";
    message.workflowName = object.workflowName ?? "";
    return message;
  },
};

function createBaseCondition(): Condition {
  return { attribute: "", operator: 0, value: "", attributeType: undefined };
}

export const Condition = {
  encode(message: Condition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.attribute !== undefined && message.attribute !== "") {
      writer.uint32(10).string(message.attribute);
    }
    if (message.operator !== undefined && message.operator !== 0) {
      writer.uint32(16).int32(message.operator);
    }
    if (message.value !== undefined && message.value !== "") {
      writer.uint32(26).string(message.value);
    }
    if (message.attributeType !== undefined) {
      AttributeType.encode(message.attributeType, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Condition {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCondition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.attribute = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.operator = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.value = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.attributeType = AttributeType.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Condition {
    return {
      attribute: isSet(object.attribute) ? globalThis.String(object.attribute) : "",
      operator: isSet(object.operator) ? operatorFromJSON(object.operator) : 0,
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      attributeType: isSet(object.attributeType) ? AttributeType.fromJSON(object.attributeType) : undefined,
    };
  },

  toJSON(message: Condition): unknown {
    const obj: any = {};
    if (message.attribute !== undefined && message.attribute !== "") {
      obj.attribute = message.attribute;
    }
    if (message.operator !== undefined && message.operator !== 0) {
      obj.operator = operatorToJSON(message.operator);
    }
    if (message.value !== undefined && message.value !== "") {
      obj.value = message.value;
    }
    if (message.attributeType !== undefined) {
      obj.attributeType = AttributeType.toJSON(message.attributeType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Condition>, I>>(base?: I): Condition {
    return Condition.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Condition>, I>>(object: I): Condition {
    const message = createBaseCondition();
    message.attribute = object.attribute ?? "";
    message.operator = object.operator ?? 0;
    message.value = object.value ?? "";
    message.attributeType = (object.attributeType !== undefined && object.attributeType !== null)
      ? AttributeType.fromPartial(object.attributeType)
      : undefined;
    return message;
  },
};

function createBaseAttributeType(): AttributeType {
  return { parent: "", name: "" };
}

export const AttributeType = {
  encode(message: AttributeType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.parent !== undefined && message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    if (message.name !== undefined && message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AttributeType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttributeType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AttributeType {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: AttributeType): unknown {
    const obj: any = {};
    if (message.parent !== undefined && message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.name !== undefined && message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AttributeType>, I>>(base?: I): AttributeType {
    return AttributeType.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AttributeType>, I>>(object: I): AttributeType {
    const message = createBaseAttributeType();
    message.parent = object.parent ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseCompositeGroupCondition(): CompositeGroupCondition {
  return { logicalOperator: 0, conditions: [], nestedConditions: [], groupIndex: 0 };
}

export const CompositeGroupCondition = {
  encode(message: CompositeGroupCondition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.logicalOperator !== undefined && message.logicalOperator !== 0) {
      writer.uint32(8).int32(message.logicalOperator);
    }
    if (message.conditions !== undefined && message.conditions.length !== 0) {
      for (const v of message.conditions) {
        Condition.encode(v!, writer.uint32(18).fork()).ldelim();
      }
    }
    if (message.nestedConditions !== undefined && message.nestedConditions.length !== 0) {
      for (const v of message.nestedConditions) {
        CompositeGroupCondition.encode(v!, writer.uint32(26).fork()).ldelim();
      }
    }
    if (message.groupIndex !== undefined && message.groupIndex !== 0) {
      writer.uint32(32).int32(message.groupIndex);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompositeGroupCondition {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompositeGroupCondition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.logicalOperator = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.conditions!.push(Condition.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.nestedConditions!.push(CompositeGroupCondition.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.groupIndex = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompositeGroupCondition {
    return {
      logicalOperator: isSet(object.logicalOperator) ? logicalOperatorFromJSON(object.logicalOperator) : 0,
      conditions: globalThis.Array.isArray(object?.conditions)
        ? object.conditions.map((e: any) => Condition.fromJSON(e))
        : [],
      nestedConditions: globalThis.Array.isArray(object?.nestedConditions)
        ? object.nestedConditions.map((e: any) => CompositeGroupCondition.fromJSON(e))
        : [],
      groupIndex: isSet(object.groupIndex) ? globalThis.Number(object.groupIndex) : 0,
    };
  },

  toJSON(message: CompositeGroupCondition): unknown {
    const obj: any = {};
    if (message.logicalOperator !== undefined && message.logicalOperator !== 0) {
      obj.logicalOperator = logicalOperatorToJSON(message.logicalOperator);
    }
    if (message.conditions?.length) {
      obj.conditions = message.conditions.map((e) => Condition.toJSON(e));
    }
    if (message.nestedConditions?.length) {
      obj.nestedConditions = message.nestedConditions.map((e) => CompositeGroupCondition.toJSON(e));
    }
    if (message.groupIndex !== undefined && message.groupIndex !== 0) {
      obj.groupIndex = Math.round(message.groupIndex);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompositeGroupCondition>, I>>(base?: I): CompositeGroupCondition {
    return CompositeGroupCondition.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompositeGroupCondition>, I>>(object: I): CompositeGroupCondition {
    const message = createBaseCompositeGroupCondition();
    message.logicalOperator = object.logicalOperator ?? 0;
    message.conditions = object.conditions?.map((e) => Condition.fromPartial(e)) || [];
    message.nestedConditions = object.nestedConditions?.map((e) => CompositeGroupCondition.fromPartial(e)) || [];
    message.groupIndex = object.groupIndex ?? 0;
    return message;
  },
};

function createBaseConditionOptions(): ConditionOptions {
  return { groupCondition: undefined, percentOfRandomSample: undefined, confidenceScore: undefined };
}

export const ConditionOptions = {
  encode(message: ConditionOptions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupCondition !== undefined) {
      CompositeGroupCondition.encode(message.groupCondition, writer.uint32(10).fork()).ldelim();
    }
    if (message.percentOfRandomSample !== undefined) {
      writer.uint32(21).float(message.percentOfRandomSample);
    }
    if (message.confidenceScore !== undefined) {
      writer.uint32(29).float(message.confidenceScore);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConditionOptions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConditionOptions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.groupCondition = CompositeGroupCondition.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.percentOfRandomSample = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.confidenceScore = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConditionOptions {
    return {
      groupCondition: isSet(object.groupCondition)
        ? CompositeGroupCondition.fromJSON(object.groupCondition)
        : undefined,
      percentOfRandomSample: isSet(object.percentOfRandomSample)
        ? globalThis.Number(object.percentOfRandomSample)
        : undefined,
      confidenceScore: isSet(object.confidenceScore) ? globalThis.Number(object.confidenceScore) : undefined,
    };
  },

  toJSON(message: ConditionOptions): unknown {
    const obj: any = {};
    if (message.groupCondition !== undefined) {
      obj.groupCondition = CompositeGroupCondition.toJSON(message.groupCondition);
    }
    if (message.percentOfRandomSample !== undefined) {
      obj.percentOfRandomSample = message.percentOfRandomSample;
    }
    if (message.confidenceScore !== undefined) {
      obj.confidenceScore = message.confidenceScore;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConditionOptions>, I>>(base?: I): ConditionOptions {
    return ConditionOptions.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConditionOptions>, I>>(object: I): ConditionOptions {
    const message = createBaseConditionOptions();
    message.groupCondition = (object.groupCondition !== undefined && object.groupCondition !== null)
      ? CompositeGroupCondition.fromPartial(object.groupCondition)
      : undefined;
    message.percentOfRandomSample = object.percentOfRandomSample ?? undefined;
    message.confidenceScore = object.confidenceScore ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
