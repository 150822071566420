import { Add, AutoFixHigh } from '@mui/icons-material';
import { Box, Breadcrumbs, Fab, Stack } from '@mui/material';
import { CurrentBreadcrumb } from '../../components/core/StyledBreadcrumb';
import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import HyperparametersList from './components/HyperparametersList';
import { observer } from 'mobx-react';
import { useStore } from '../../hooks/useStore';
import { PERMISSION_MANAGE_USERS } from '../../utils/constants';

const Hyperparameters: React.FC = observer(() => {
  const navigate = useNavigate();
  const store = useStore();
  const { user } = store.authStore;

  return (
    <Box
      sx={{
        margin: '16px',
      }}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <Breadcrumbs separator='>'>
          <CurrentBreadcrumb
            label='Hyperparameters'
            icon={<AutoFixHigh fontSize='small' color='info' />}
          />
        </Breadcrumbs>
        {user?.permittedActions?.includes(PERMISSION_MANAGE_USERS) && (
          <Fab
            variant='extended'
            color='primary'
            onClick={() => navigate('create')}
          >
            <Add sx={{ marginRight: '4px' }} />
            Hyperparameter
          </Fab>
        )}
      </Stack>
      <Box>
        <HyperparametersList />
      </Box>
    </Box>
  );
});

export default memo(Hyperparameters);
