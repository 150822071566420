import OrbyColorPalette from '../colors/ColorPalette';

export const getButtonHeight = (size?: string) => {
  switch (size) {
    case 'small':
      return '2.25rem';
    case 'medium':
      return '2.5rem';
    case 'large':
      return '2.75rem';
    default:
      return '2.25rem'; // Default to 'small' if no size is specified
  }
};

export const getButtonPadding = (size?: string) => {
  switch (size) {
    case 'small':
      return '8px 14px 8px 14px';
    case 'medium':
      return '10px 16px 10px 16px';
    case 'large':
      return '10px 18px 10px 18px';
    default:
      return '8px 14px 8px 14px'; // Default to'small' if no size is specified
  }
};

export const BUTTON_VARIANTS = {
  'primary-contained': {
    background: OrbyColorPalette['blue-700'],
    border: OrbyColorPalette['blue-700'],
    color: OrbyColorPalette['white-0'],
    boxShadow: '0px 1px 2px 0px #1018280D',
    hover: {
      background: OrbyColorPalette['blue-900'],
      color: OrbyColorPalette['white-0'],
      border: OrbyColorPalette['blue-900'],
      boxShadow: '0px 1px 2px 0px #1018280D',
    },
    focus: {
      background: OrbyColorPalette['blue-700'],
      color: OrbyColorPalette['white-0'],
      border: OrbyColorPalette['blue-700'],
      boxShadow: '0px 0px 0px 4px #F4EBFF',
    },
    disabled: {
      background: OrbyColorPalette['blue-100'],
      border: OrbyColorPalette['blue-100'],
      color: OrbyColorPalette['white-0'],
      boxShadow: '0px 1px 2px 0px #1018280D',
    },
  },
  'primary-contained-black': {
    background: OrbyColorPalette['black-0'],
    border: OrbyColorPalette['black-0'],
    color: OrbyColorPalette['white-0'],
    boxShadow: '0px 1px 2px 0px #1018280D',
    hover: {
      background: OrbyColorPalette['grey-700'],
      color: OrbyColorPalette['white-0'],
      border: OrbyColorPalette['black-0'],
      boxShadow: '0px 1px 2px 0px #1018280D',
    },
    focus: {
      background: OrbyColorPalette['grey-700'],
      color: OrbyColorPalette['white-0'],
      border: OrbyColorPalette['black-0'],
      boxShadow: '0px 0px 0px 4px #F4EBFF',
    },
    disabled: {
      background: OrbyColorPalette['black-0'],
      border: OrbyColorPalette['black-0'],
      color: OrbyColorPalette['white-0'],
      boxShadow: '0px 1px 2px 0px #1018280D',
    },
  },
  'primary-outline': {
    background: 'transparent',
    color: OrbyColorPalette['blue-700'],
    border: OrbyColorPalette['blue-700'],
    boxShadow: '0px 1px 2px 0px #1018280D',
    hover: {
      background: OrbyColorPalette['purple-100'],
      color: OrbyColorPalette['blue-700'],
      border: OrbyColorPalette['blue-700'],
      boxShadow: '0px 1px 2px 0px #1018280D',
    },
    focus: {
      background: OrbyColorPalette['purple-100'],
      color: OrbyColorPalette['blue-700'],
      border: OrbyColorPalette['blue-700'],
      boxShadow: '0px 0px 0px 4px #F4EBFF',
    },
    disabled: {
      background: OrbyColorPalette['white-0'],
      border: OrbyColorPalette['grey-200'],
      color: OrbyColorPalette['grey-300'],
      boxShadow: '0px 1px 2px 0px #1018280D',
    },
  },
  'primary-outlined-black': {
    background: 'transparent',
    color: OrbyColorPalette['black-0'],
    border: OrbyColorPalette['black-0'],
    boxShadow: '0px 1px 2px 0px #1018280D',
    hover: {
      background: OrbyColorPalette['purple-100'],
      color: OrbyColorPalette['black-0'],
      border: OrbyColorPalette['black-0'],
      boxShadow: '0px 1px 2px 0px #1018280D',
    },
    focus: {
      background: OrbyColorPalette['purple-100'],
      color: OrbyColorPalette['black-0'],
      border: OrbyColorPalette['black-0'],
      boxShadow: '0px 0px 0px 4px #F4EBFF',
    },
    disabled: {
      background: OrbyColorPalette['white-0'],
      border: OrbyColorPalette['grey-200'],
      color: OrbyColorPalette['grey-300'],
      boxShadow: '0px 1px 2px 0px #1018280D',
    },
  },
  'primary-text': {
    background: OrbyColorPalette['white-0'],
    color: OrbyColorPalette['blue-700'],
    border: OrbyColorPalette['white-0'],
    boxShadow: 'unset',
    hover: {
      background: OrbyColorPalette['purple-50'],
      color: OrbyColorPalette['blue-700'],
      border: OrbyColorPalette['white-0'],
      boxShadow: 'unset',
    },
    focus: {
      background: OrbyColorPalette['white-0'],
      color: OrbyColorPalette['blue-700'],
      border: OrbyColorPalette['white-0'],
      boxShadow: 'unset',
    },
    disabled: {
      background: OrbyColorPalette['white-0'],
      border: OrbyColorPalette['white-0'],
      color: OrbyColorPalette['grey-300'],
      boxShadow: 'unset',
    },
  },
  'primary-text-flat': {
    background: 'transparent',
    color: OrbyColorPalette['blue-700'],
    border: 'unset',
    boxShadow: 'unset',
    hover: {
      background: 'transparent',
      color: OrbyColorPalette['blue-900'],
      border: 'unset',
      boxShadow: 'unset',
    },
    focus: {
      background: 'transparent',
      color: OrbyColorPalette['blue-700'],
      border: 'unset',
      boxShadow: 'unset',
    },
    disabled: {
      background: 'transparent',
      border: 'unset',
      color: OrbyColorPalette['grey-300'],
      boxShadow: 'unset',
    },
  },
  'monochrome-outline': {
    background: OrbyColorPalette['white-0'],
    color: OrbyColorPalette['grey-700'],
    border: OrbyColorPalette['grey-300'],
    boxShadow: '0px 1px 2px 0px #1018280D',
    hover: {
      background: OrbyColorPalette['grey-50'],
      color: OrbyColorPalette['grey-700'],
      border: OrbyColorPalette['grey-300'],
      boxShadow: '0px 1px 2px 0px #1018280D',
    },
    focus: {
      background: OrbyColorPalette['white-0'],
      color: OrbyColorPalette['grey-700'],
      border: OrbyColorPalette['grey-300'],
      boxShadow: '0px 0px 0px 4px #F2F4F7',
    },
    disabled: {
      background: OrbyColorPalette['white-0'],
      border: OrbyColorPalette['grey-200'],
      color: OrbyColorPalette['grey-300'],
      boxShadow: '0px 1px 2px 0px #1018280D',
    },
  },
  'monochrome-text': {
    background: OrbyColorPalette['white-0'],
    color: OrbyColorPalette['grey-500'],
    border: OrbyColorPalette['white-0'],
    boxShadow: 'unset',
    hover: {
      background: OrbyColorPalette['grey-50'],
      color: OrbyColorPalette['grey-600'],
      border: OrbyColorPalette['white-0'],
      boxShadow: 'unset',
    },
    focus: {
      background: OrbyColorPalette['white-0'],
      color: OrbyColorPalette['grey-500'],
      border: OrbyColorPalette['white-0'],
      boxShadow: 'unset',
    },
    disabled: {
      background: OrbyColorPalette['white-0'],
      border: OrbyColorPalette['white-0'],
      color: OrbyColorPalette['grey-300'],
      boxShadow: 'unset',
    },
  },
  'monochrome-text-flat': {
    background: 'unset',
    color: OrbyColorPalette['grey-500'],
    border: 'unset',
    boxShadow: 'unset',
    hover: {
      background: 'unset',
      color: OrbyColorPalette['grey-600'],
      border: 'unset',
      boxShadow: 'unset',
    },
    focus: {
      background: 'unset',
      color: OrbyColorPalette['grey-500'],
      border: 'unset',
      boxShadow: 'unset',
    },
    disabled: {
      background: 'unset',
      border: 'unset',
      color: OrbyColorPalette['grey-300'],
      boxShadow: 'unset',
    },
  },
};
