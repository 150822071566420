import { Box } from '@mui/material';
import CustomTextField from '../../../../components/core/CustomTextField';
import React from 'react';

const DescriptionStep: React.FC<{
  isViewOnly: boolean;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}> = ({ isViewOnly, value, setValue }) => {
  return (
    <Box display={'flex'} gap={'50px'} justifyContent={'space-between'}>
      <Box width={'70%'} marginBottom={'15px'}>
        <Box marginLeft={'14px'} marginTop={'21px'}>
          <CustomTextField
            label='Hyperparameter name'
            size='small'
            value={value}
            hasDescription={true}
            autoFocus
            color={isViewOnly ? '#DDDBDA' : ''}
            disabled={isViewOnly}
            onChange={(e) => setValue(e.target.value)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DescriptionStep;
