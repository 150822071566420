import { Close } from '@mui/icons-material';
import {
  Button,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  Slider,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CustomTypography, {
  TypographyType,
} from '../../../../components/core/CustomTypography';
import React from 'react';

const BillingReportForm: React.FC<{
  handleBillingReportFormClose: () => void;
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
  setDateRange: React.Dispatch<
    React.SetStateAction<{
      startDate: Date;
      endDate: Date;
    }>
  >;
  discount: number;
  setDiscount: React.Dispatch<React.SetStateAction<number>>;
  downloadBillingReport: () => void;
}> = ({
  handleBillingReportFormClose,
  dateRange,
  setDateRange,
  discount,
  setDiscount,
  downloadBillingReport,
}) => {
  const handleBlur = () => {
    if (discount < 0) {
      setDiscount(0);
    } else if (discount > 100) {
      setDiscount(100);
    }
  };
  return (
    <Grid container spacing={2} sx={{ padding: '16px' }}>
      <DialogTitle>Get Billing Report</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleBillingReportFormClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'black',
        }}
      >
        <Close />
      </IconButton>
      <Grid item xs={12}>
        <CustomTypography
          typographyType={TypographyType.Label}
          sx={{ margin: '5px 0' }}
        >
          Date Range
        </CustomTypography>
      </Grid>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid item xs={6}>
          <DatePicker
            label='Start Date'
            value={dateRange.startDate}
            onChange={(newValue) =>
              setDateRange({ ...dateRange, startDate: newValue! })
            }
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label='End Date'
            value={dateRange.endDate}
            onChange={(newValue) =>
              setDateRange({ ...dateRange, endDate: newValue! })
            }
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
      </LocalizationProvider>
      <Grid item xs={12}>
        <CustomTypography
          typographyType={TypographyType.Label}
          sx={{ margin: '5px 0' }}
        >
          Discount on final amount
        </CustomTypography>
      </Grid>
      <Grid item xs>
        <Slider
          value={typeof discount === 'number' ? discount : 0}
          onChange={(event: Event, newValue: number | number[]) => {
            setDiscount(newValue as number);
          }}
          color='primary'
          sx={{ marginX: '10px' }}
        />
      </Grid>
      <Grid item>
        <Input
          value={discount}
          size='small'
          onChange={(e) =>
            setDiscount(e.target.value === '' ? 0 : Number(e.target.value))
          }
          onBlur={handleBlur}
          endAdornment='%'
          inputProps={{
            step: 10,
            min: 0,
            max: 100,
            type: 'number',
          }}
          sx={{ marginX: '10px' }}
        />
      </Grid>
      <DialogActions
        sx={{
          justifyContent: 'end',
          width: '100%',
        }}
      >
        <Button
          variant='contained'
          color='primary'
          onClick={downloadBillingReport}
        >
          Submit
        </Button>
      </DialogActions>
    </Grid>
  );
};

export default BillingReportForm;
