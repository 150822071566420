import { makeAutoObservable, observable, runInAction } from 'mobx';
import { OrgInfo, User } from 'protos/pb/v1alpha1/user';
import {
  AddUserToOrganizationRequest,
  GetResponse,
  ListUsersRequest,
  UpdatePasswordRequest,
  UpdateRequest,
} from 'protos/pb/v1alpha1/users_service';
import { storageService } from '../services/StorageService';
import { userService } from '../services/UserService';
import { RootStore } from './store';

class UserStore {
  rootStore: RootStore;
  @observable users: User[] = [];
  @observable loadingUsers = false;
  @observable loadedUsers = false;
  @observable fetchingLoggedInUser = false;
  @observable updatingPassword = false;
  @observable updateLoading = false;
  @observable createLoading = false;
  @observable nextPageToken?: string;
  @observable totalSize?: number;
  @observable listUsersError?: any;
  @observable loggedInUser?: User;
  @observable addUserError?: any;
  @observable addedUser?: User;
  @observable updateUserError?: any;
  @observable updatedUser?: User;
  @observable selectedOrgInfo?: OrgInfo;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  reset = () => {
    runInAction(() => {
      this.users = [];
      this.loadingUsers = false;
      this.loadedUsers = false;
      this.fetchingLoggedInUser = false;
      this.updatingPassword = false;
      this.updateLoading = false;
      this.createLoading = false;
      this.nextPageToken = undefined;
      this.totalSize = undefined;
      this.listUsersError = undefined;
      this.loggedInUser = undefined;
      this.addUserError = undefined;
      this.addedUser = undefined;
      this.updateUserError = undefined;
      this.updatedUser = undefined;
      this.selectedOrgInfo = undefined;
    });
  };

  getLoggedInUser = async () => {
    runInAction(() => {
      this.fetchingLoggedInUser = true;
    });
    try {
      const resp: GetResponse = await userService.getLoggedInUser();
      const orgResourceName: string | null =
        await storageService.getStoredOrgResourceName();
      const orgInfos = resp.user?.orgInfos;

      if (orgInfos?.length) {
        if (!orgResourceName) {
          await storageService.setStoredOrgResourceName(
            orgInfos[0].orgResourceName as string,
          );
          this.selectedOrgInfo = orgInfos[0];
        } else {
          const orgInfo: OrgInfo = orgInfos.find(
            (o) => o.orgResourceName === orgResourceName,
          ) as OrgInfo;
          this.selectedOrgInfo = orgInfo;
        }
      }

      // permitted actions are sent when we get the logged in user,
      // so we set it in the user object in auth store too
      this.rootStore.authStore.setLoggedInUser(resp.user!);

      runInAction(() => {
        this.loggedInUser = resp.user as User;
        this.fetchingLoggedInUser = false;
      });
    } catch (e: any) {
      runInAction(() => {
        this.fetchingLoggedInUser = false;
        this.listUsersError = e.message;
      });
    }
  };

  updateUser = async (data: UpdateRequest) => {
    runInAction(() => {
      this.updateLoading = true;
    });
    try {
      const { response, error } = await userService.updateUser(data);
      if (response) {
        runInAction(() => {
          this.updatedUser = response.user;
          this.updateLoading = false;
        });
      } else {
        runInAction(() => {
          this.updateUserError = error;
          this.updateLoading = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.updateUserError = error;
        this.updateLoading = false;
      });
    }
  };

  updatePassword = async (data: UpdatePasswordRequest) => {
    runInAction(() => {
      this.updatingPassword = true;
    });
    try {
      const { response, error } = await userService.updatePassword(data);
      if (response) {
        runInAction(() => {
          this.updatingPassword = false;
        });
      } else {
        runInAction(() => {
          this.updateUserError = error;
          this.updatingPassword = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.updateUserError = error;
        this.updatingPassword = false;
      });
    }
  };

  listUsers = async (data: ListUsersRequest, refresh: boolean) => {
    runInAction(() => {
      this.loadingUsers = true;
    });
    try {
      const { response, error } = await userService.listUsers(data);
      if (response) {
        runInAction(() => {
          if (refresh) {
            this.users = response.users!;
          } else {
            this.users.push(...response.users!);
          }
          this.nextPageToken = response.nextPageToken;
          this.totalSize = response.totalSize;
          this.loadingUsers = false;
          this.loadedUsers = true;
        });
      } else {
        runInAction(() => {
          this.listUsersError = error;
          this.loadingUsers = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.listUsersError = error;
        this.loadingUsers = false;
      });
    }
  };

  addUserToOrganization = async (data: AddUserToOrganizationRequest) => {
    runInAction(() => {
      this.createLoading = true;
    });
    try {
      const { response, error } = await userService.addUserToOrganization(data);
      if (response) {
        runInAction(() => {
          this.addedUser = response;
          this.users.push(response);
          this.totalSize = (this.totalSize ?? 0) + 1;
          this.createLoading = false;
        });
      } else {
        runInAction(() => {
          this.addUserError = error;
          this.createLoading = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.addUserError = error;
        this.createLoading = false;
      });
    }
  };

  setSelectedOrgInfo = (orgInfo: OrgInfo) => {
    runInAction(() => {
      this.selectedOrgInfo = orgInfo;
    });
    storageService.setStoredOrgResourceName(orgInfo.orgResourceName!);
  };
}

export default UserStore;
