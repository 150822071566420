import { styled, TableRow } from '@mui/material';

const StyledTableRow = styled(TableRow)(() => ({
  // hide last border
  // '& td, & th': {
  //   border: 0,
  // },
}));

export default StyledTableRow;
