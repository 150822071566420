export enum AuthPlatform {
  GOOGLE = 'google',
  MICROSOFT = 'microsoft',
  PASSWORD = 'password',
}

export enum WorkflowType {
  'extraction',
  'classification',
}

export enum ApplicationName {
  GoogleDrive = 'Google Drive',
  Gmail = 'Gmail',
  GoogleSheets = 'Google Sheets',
  EntityExtraction = 'Entity Extraction',
  SalesForce = 'sales_force',
  Sap = 'sap',
  SftpServer = 'SFTP Server',
  DocumentClassification = 'Document Classification',
  GenerateOutput = 'Generate Output',
  Outlook = 'Outlook',
  MSExcel = 'MS Excel',
}
