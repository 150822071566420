import { Button, CircularProgress, SxProps, Theme } from '@mui/material';
import React, { FC, memo } from 'react';

interface CustomButtonProps {
  variant?: 'text' | 'contained' | 'outlined';
  children: any;
  type?: 'button' | 'reset' | 'submit';
  form?: string;
  onClick?: () => void;
  invisible?: boolean;
  disabled?: boolean;
  loading?: boolean;
  ariaLabel?: string;
  sx?: SxProps<Theme>;
}

const CustomButton: FC<CustomButtonProps> = (props) => {
  return (
    <Button
      aria-label={props.ariaLabel}
      form={props.form}
      type={props.type}
      onClick={props?.onClick}
      disabled={props.disabled}
      sx={{
        minWidth: '120px',
        height: '40px',
        borderRadius: '4px',
        padding: '10px 16px',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '20px',
        border: '1px solid #1669F7',
        color: props.variant === 'outlined' ? '#1366F6' : '#ffffff',
        display: props.invisible ? 'none' : 'block',
        ...props.sx,
      }}
      variant={props.variant ? props.variant : 'contained'}
    >
      {props.loading ? <CircularProgress size={'27px'} /> : props.children}
    </Button>
  );
};

export default memo(CustomButton);
