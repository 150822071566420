import { Box } from '@mui/material';
import React, { FC, memo } from 'react';
import { ArrowDropDown } from '@mui/icons-material';

type StepIconProps = {
  color: string;
  bgColor: string;
};

const StepIcon: FC<StepIconProps> = ({ color, bgColor }) => {
  return (
    <Box
      bgcolor={bgColor}
      color={color}
      width={'48px'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <ArrowDropDown />
    </Box>
  );
};

export default memo(StepIcon);
