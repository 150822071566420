export const hyperparameterDetailSteps: {
  title: string;
  background: string;
  iconColor: string;
}[] = [
  {
    title: 'Hyperparameter name',
    background: '#E8F4E3',
    iconColor: '#3BA755',
  },
  {
    title: 'Default Config',
    background: '#5DB9E429',
    iconColor: '#6198DE',
  },
  {
    title: 'Few Shot Config',
    background: '#E8E8FC',
    iconColor: '#4F52B2',
  },
  {
    title: 'Document Classification Config',
    background: '#FBBA2345',
    iconColor: '#F6BD16',
  },
  {
    title: 'Fetch Similar Documents Config',
    background: '#5DB9E429',
    iconColor: '#6198DE',
  },
];
